import {
  completeTypes,
  createTypes,
  withPostFailure,
  withPostSuccess
} from 'redux-recompose';

import { getPayments, savePayment } from '../../services/payments';

export const actions = createTypes(
  completeTypes({
    primaryActions: ['GET_PAYMENTS'],
    ignoredActions: ['ADD_PAYMENT']
  }),
  '@@PAYMENTS'
);

const TARGET = {
  PAYMENTS: 'payments'
};

export const actionCreators = {
  getPayments: payload => ({
    type: actions.GET_PAYMENTS,
    target: TARGET.PAYMENTS,
    service: getPayments,
    payload
  }),
  addPayment: (payload, onSuccess, onFailure) => ({
    type: actions.ADD_PAYMENT,
    payload,
    service: savePayment,
    injections: [
      withPostSuccess((_dispatch, _response) => {
        onSuccess();
      }),
      withPostFailure((_dispatch, _response) => {
        onFailure();
      })
    ]
  })
};
