import { api } from '../api';

const ticketsPath = `/tickets`;

export const getTickets = data =>
  api.post(`${ticketsPath}/filter?page=${data.page}`, data);

export const saveTicket = data => api.post(`${ticketsPath}`, data);

export const deleteTicket = id => api.delete(`${ticketsPath}/${id}`);

export const updateTicket = data => api.put(`${ticketsPath}/${data.id}`, data);

export const getTicketData = id => api.get(`${ticketsPath}/${id}`);
