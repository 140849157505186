import React from 'react';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPen,
  faTrashCan,
  faEye,
  faBars
} from '@fortawesome/free-solid-svg-icons';

import './CustomTable.css';
import TablePagination from './TablePagination';

function CustomTable({
  headers,
  rows,
  onEdit = null,
  onRemove = null,
  onShow = null,
  allData = {},
  setPage,
  page,
  changePermissions
}) {
  const columnsHeader = headers.map((item, index) => (
    <th key={index} align="center">
      {item.title}
    </th>
  ));

  const rowsBody = rows.map(item => {
    let items = [];
    for (const property in item) {
      items.push(<td align="center">{item[property]}</td>);
    }
    if (onEdit) {
      const handleEdit = () => onEdit(item);
      items.push(
        <td align="center">
          <FontAwesomeIcon
            icon={faPen}
            onClick={handleEdit}
            className={'icon'}
          />
        </td>
      );
    }
    if (onRemove) {
      const handleRemove = () => onRemove(item.id);
      items.push(
        <td align="center">
          <FontAwesomeIcon
            icon={faTrashCan}
            onClick={handleRemove}
            className={'icon'}
          />
        </td>
      );
    }
    if (onShow) {
      const handleShow = () => onShow(item.id);
      items.push(
        <td>
          <FontAwesomeIcon
            icon={faEye}
            onClick={handleShow}
            className={'icon'}
          />
        </td>
      );
    }
    if (changePermissions) {
      const handleChangePermissions = () =>
        changePermissions(item.id, item.nombre);
      items.push(
        <td>
          <FontAwesomeIcon
            icon={faBars}
            onClick={handleChangePermissions}
            className={'icon'}
          />
        </td>
      );
    }
    return <tr key={item.id}>{items}</tr>;
  });
  return (
    <>
      <Table className="custom-table">
        <thead>
          <tr>{columnsHeader}</tr>
        </thead>
        <tbody>{rowsBody}</tbody>
      </Table>
      {allData.current_page && (
        <TablePagination
          currentPage={allData.current_page}
          lastPage={allData.last_page}
          setPage={setPage}
          page={page}
        />
      )}
    </>
  );
}

export default CustomTable;
