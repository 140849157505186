import {
  completeTypes,
  createTypes,
  withPostFailure,
  withPostSuccess,
  withSuccess
} from 'redux-recompose';

import { removeApiHeaders, setApiHeaders } from '../../api';
import {
  login,
  logout,
  saveUser,
  getUsers,
  deleteUser,
  updateUser
} from '../../services/users';

export const actions = createTypes(
  completeTypes({
    primaryActions: ['LOGIN', 'LOGOUT', 'GET_USERS'],
    ignoredActions: [
      'REMOVE_CURRENT_USER',
      'ADD_USER',
      'DELETE_USER',
      'UPDATE_USER',
      'SET_CURRENT_USER_LOADING',
      'SET_CURRENT_USER',
      'INIT'
    ]
  }),
  '@@USER'
);

const TARGETS = {
  CURRENT_USER: 'currentUser',
  ALL_USERS: 'allUsers',
  CURRENT_USER_LOADING: 'currentUserLoading'
};

export const actionCreators = {
  init: () => (dispatch, getState) => {
    const { currentUser } = getState().user;
    if (currentUser) setApiHeaders(currentUser.accessToken);
    dispatch(actionCreators.setCurrentUser(currentUser));
  },
  login: payload => ({
    type: actions.LOGIN,
    target: TARGETS.CURRENT_USER,
    service: login,
    payload,
    injections: [
      withSuccess((dispatch, response) => {
        setApiHeaders(response?.data?.access_token);
        dispatch(
          actionCreators.setCurrentUser({
            accessToken: response?.data?.access_token,
            ...response?.data?.user
          })
        );
        dispatch(actionCreators.setCurrentUserLoading(false));
      }),
      withPostFailure((_dispatch, response) => {
        if (response?.data?.message === 'Unauthorized') {
          alert('Usuario o contraseña incorrecta');
        }
      })
    ]
  }),
  setCurrentUser: user => ({
    type: actions.SET_CURRENT_USER,
    target: TARGETS.CURRENT_USER,
    payload: user
  }),
  setCurrentUserLoading: loading => ({
    type: actions.SET_CURRENT_USER_LOADING,
    target: TARGETS.CURRENT_USER_LOADING,
    payload: loading
  }),
  logout: () => ({
    type: actions.LOGOUT,
    target: TARGETS.CURRENT_USER,
    service: logout,
    injections: [
      withPostSuccess((dispatch, _response) => {
        removeApiHeaders();
        dispatch(actionCreators.removeCurrentUser());
      }),
      withPostFailure((dispatch, _response) => {
        removeApiHeaders();
        dispatch(actionCreators.removeCurrentUser());
      })
    ]
  }),
  removeCurrentUser: () => ({
    type: actions.REMOVE_CURRENT_USER,
    target: TARGETS.CURRENT_USER,
    payload: null
  }),
  addUser: payload => ({
    type: actions.ADD_USER,
    payload,
    service: saveUser,
    injections: [
      withPostSuccess((dispatch, _response) => {
        dispatch(actionCreators.getUsers());
      })
    ]
  }),
  getUsers: () => ({
    type: actions.GET_USERS,
    target: TARGETS.ALL_USERS,
    service: getUsers
  }),
  deleteUser: id => ({
    type: actions.DELETE_USER,
    payload: id,
    service: deleteUser,
    injections: [
      withPostSuccess((dispatch, _response) => {
        dispatch(actionCreators.getUsers());
      })
    ]
  }),
  updateUser: data => ({
    type: actions.UPDATE_USER,
    payload: data,
    service: updateUser,
    injections: [
      withPostSuccess((dispatch, _response) => {
        dispatch(actionCreators.getUsers());
      })
    ]
  })
};
