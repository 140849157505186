import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faPrint } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { actionCreators as remittancesActions } from '../../../../../config/redux/remittances/actions';
import WarningModal from '../../../../components/WarningModal';

function RemittanceRow({
  id,
  created_at,
  origen,
  destino,
  usuario,
  valor,
  customer_destinatario,
  customer_remitente,
  hasPermissionEdit,
  estado,
  ...props
}) {
  const createdDate = new Date(created_at);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handlePressDeleteTicket = () => setShow(true);
  const handleDelete = () => {
    dispatch(remittancesActions.deleteRemittance(id));
    setShow(false);
  };
  const modalMessage = `¿Seguro quiere anular la remesa No. ${id}?`;
  const handleCloseModal = () => setShow(false);
  const handlePressPrint = () => {
    dispatch(
      remittancesActions.setLastRemittance({
        id,
        created_at,
        origen,
        destino,
        usuario,
        valor,
        customer_destinatario,
        customer_remitente,
        estado,
        ...props
      })
    );
    navigate(`/imprimir-remesa`);
  };
  return (
    <React.Fragment>
      <WarningModal
        message={modalMessage}
        handleCloseModal={handleCloseModal}
        onPressOk={handleDelete}
        show={show}
      />

      <tr key={id}>
        <td align="center">{id}</td>
        <td>{origen}</td>
        <td>{destino}</td>
        <td align="right">{`$ ${Number(valor).toLocaleString('es-ES')}`}</td>
        <td align="center">{customer_destinatario.id}</td>
        <td align="center">{customer_destinatario.nombre}</td>
        <td align="center">{customer_remitente.id}</td>
        <td align="center">{customer_remitente.nombre}</td>
        <td align="center">{usuario}</td>
        <td align="center">{estado}</td>
        <td align="center">{createdDate.toLocaleString()}</td>
        {hasPermissionEdit && (
          <td>
            <FontAwesomeIcon
              icon={faBan}
              onClick={handlePressDeleteTicket}
              className={`icon ${estado === 'ANULADA' && 'fa-disabled'}`}
            />
          </td>
        )}
        <td>
          <FontAwesomeIcon
            icon={faPrint}
            onClick={handlePressPrint}
            className={'icon'}
          />
        </td>
      </tr>
    </React.Fragment>
  );
}

export default RemittanceRow;
