import React, { memo } from 'react';

import './CustomButton.css';

const STYLES = ['btn--primary', 'btn--outline', 'btn--test'];

const SIZES = ['btn--medium', 'btn--large'];

function CustomButton({ children, type, onClick, buttonStyle, buttonSize }) {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  return (
    <button
      className={`btn-zz ${checkButtonStyle} ${checkButtonSize}`}
      onClick={onClick}
      type={type}>
      {children}
    </button>
  );
}

export default memo(CustomButton);
