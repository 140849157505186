import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';

import { actionCreators as customersAction } from '../../config/redux/customers/actions';

import CustomTextInput from './CustomTextInput';
import CustomButton from './CustomButton';
import './ModalForm.css';

function CustomerForm({ item = null, show, handleClose }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset
  } = useForm();
  const dispatch = useDispatch();
  const onSubmit = data => {
    dispatch(
      item?.id
        ? customersAction.updateCustomer(data)
        : customersAction.addCustomer(data)
    );
    reset();
    handleClose();
  };

  useEffect(() => {
    if (item?.id) {
      Object.entries(item).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [item, setValue]);

  const commonProps = { errors, register };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomTextInput
            name={'id'}
            title={'CÉDULA'}
            {...commonProps}
            readOnly={item?.id}
          />
          <CustomTextInput
            name={'nombre'}
            required
            {...commonProps}
            title={'NOMBRE'}
          />
          <CustomTextInput
            name={'telefono'}
            {...commonProps}
            title={'TELÉFONO'}
            required
          />
          <div className="btn-group">
            <CustomButton type="submit">Guardar</CustomButton>
            <CustomButton
              type="button"
              onClick={handleClose}
              buttonStyle={'btn--outline'}
            >
              Cancelar
            </CustomButton>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default CustomerForm;
