import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { handleExportToXlsx } from '../../../utils/xlsxUtils';
import { actionCreators as TicketsActions } from '../../../config/redux/tickets/actions';
import CustomTextInput from '../../components/CustomTextInput';
import CustomButton from '../../components/CustomButton';
import SelectCitiesPrice from '../../components/SelectCitiesPrice';
import EmptyTable from '../../components/EmptyTable';
import useLoading from '../../../hooks/useLoading';
import { usePermissions } from '../../../hooks/usePermissions';

import TicketsTable from './components/TicketsTable';

function Tickets() {
  const [page, setPage] = useState(1);
  const { tickets, ticketsLoading } = useSelector(state => state.tickets);
  const hasPermissionEdit = usePermissions();
  const navigate = useNavigate();

  const { prices } = useSelector(state => state.prices);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(TicketsActions.getTickets({ page }));
  }, [dispatch, page]);

  const ticketsTotal = tickets?.data
    ? tickets?.data
        .map(item => item.precio)
        .reduce((prev, pre) => Number(prev) + Number(pre), 0)
    : 0;

  useLoading(ticketsLoading);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  const commonProps = { errors, register };

  const handleSeeAll = () => {
    dispatch(TicketsActions.getTickets({ page: 1 }));
    reset();
  };
  const onSubmit = data => {
    dispatch(TicketsActions.getTickets({ page: 1, ...data }));
  };
  const tableId = 'ticketsTable';
  const addRowsToExport = [
    { text: 'Total', origin: { origin: -1 } },
    { text: ticketsTotal, origin: { origin: -1 } }
  ];
  const handleExport = () => {
    handleExportToXlsx(tableId, 'Tiquetes', addRowsToExport);
  };
  const handleNewTicket = () => navigate('/ventaTicket');
  return (
    <React.Fragment>
      <h2>Tiquetes</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <CustomTextInput
            name={'id'}
            title={'TIQUETE'}
            {...commonProps}
            column="col-md-2"
          />
          <CustomTextInput
            name={'cliente'}
            title={'CÉDULA'}
            {...commonProps}
            column="col-md-2"
          />
          <SelectCitiesPrice
            name={'origen'}
            {...commonProps}
            prices={prices}
            column="col-md-3"
          />
          <SelectCitiesPrice
            name={'destino'}
            {...commonProps}
            prices={prices}
            column="col-md-3"
          />
          <CustomTextInput
            name={'usuario'}
            title={'VENDEDOR'}
            {...commonProps}
            column="col-md-2"
          />
          <CustomTextInput
            name={'estado'}
            title={'ESTADO'}
            {...commonProps}
            column="col-md-2"
          />
        </div>
        <div className="row">
          <CustomTextInput
            name={'fecha_ini'}
            title={'F. viaje ini.'}
            {...commonProps}
            column="col-md-2"
            type={'date'}
          />
          <CustomTextInput
            name={'fecha_fin'}
            title={'F. viaje fin.'}
            {...commonProps}
            column="col-md-2"
            type={'date'}
          />
          <CustomTextInput
            name={'created_ini'}
            title={'F. venta ini.'}
            {...commonProps}
            column="col-md-2"
            type={'date'}
          />
          <CustomTextInput
            name={'created_fin'}
            title={'F. venta fin.'}
            {...commonProps}
            column="col-md-2"
            type={'date'}
          />
        </div>
        <div>
          <CustomButton type={'submit'}>Filtrar</CustomButton>
          <CustomButton
            type={'button'}
            buttonStyle={'btn--outline'}
            onClick={handleSeeAll}>
            Ver todos
          </CustomButton>
          <CustomButton
            type={'button'}
            buttonStyle={'btn--outline'}
            onClick={handleExport}>
            Exportar
          </CustomButton>
          <CustomButton
            type={'button'}
            buttonStyle="btn--primary"
            onClick={handleNewTicket}>
            Nuevo
          </CustomButton>
        </div>
      </form>
      <div>
        <h3>{`Total: $ ${ticketsTotal.toLocaleString('es-ES')}`}</h3>
      </div>
      <div>
        {tickets?.data?.length > 0 ? (
          <TicketsTable
            tableId={tableId}
            tickets={tickets}
            hasPermissionEdit={hasPermissionEdit}
            page={page}
            setPage={setPage}
          />
        ) : (
          <EmptyTable />
        )}
      </div>
    </React.Fragment>
  );
}

export default Tickets;
