import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';

import { actionCreators as pricesActions } from '../../config/redux/prices/actions';

import CustomTextInput from './CustomTextInput';
import CustomButton from './CustomButton';
import './ModalForm.css';

function PriceForm({ item = null, show, handleClose }) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm();
  const handleCancel = () => {
    reset();
    handleClose();
  };
  const onSubmit = data => {
    dispatch(
      item.id ? pricesActions.updatePrice(data) : pricesActions.addPrice(data)
    );

    handleCancel();
  };

  useEffect(() => {
    if (item?.id) {
      Object.entries(item).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [item, setValue]);

  const commonProps = { errors, register };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)} id={'form-price'}>
          {item?.id && (
            <CustomTextInput
              name={'id'}
              title={'ID'}
              {...commonProps}
              readOnly
            />
          )}
          <CustomTextInput
            name={'origen'}
            required
            {...commonProps}
            title={'ORIGEN'}
          />
          <CustomTextInput
            name={'destino'}
            required
            {...commonProps}
            title={'DESTINO'}
          />
          <CustomTextInput name={'precio'} {...commonProps} title={'PRECIO'} />
          <div className="btn-group">
            <CustomButton type={'submit'}>Guardar</CustomButton>
            <CustomButton
              type={'button'}
              buttonStyle={'btn--outline'}
              onClick={handleCancel}>
              Cancelar
            </CustomButton>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default PriceForm;
