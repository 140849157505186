import {
  completeReducer,
  completeState,
  createReducer,
  onReadValue
} from 'redux-recompose';
import Immutable from 'seamless-immutable';

import { actions } from './actions';

const stateDescription = {
  description: {
    remittances: [],
    lastRemittance: null
  }
};

export const initialState = completeState(stateDescription);

const reducerDescription = {
  primaryActions: [actions.GET_REMITTANCES, actions.ADD_REMITTANCE],
  override: {
    [actions.SET_LAST_REMITTANCE]: onReadValue()
  }
};

export default createReducer(
  Immutable(initialState),
  completeReducer(reducerDescription)
);
