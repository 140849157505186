import React, { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';

function NavLink({ path, onClick, title }) {
  const location = useLocation();
  const activeStyle = location.pathname === path && 'active-link';

  return (
    <li className="nav-item">
      <Link to={path} className={`nav-links ${activeStyle}`} onClick={onClick}>
        {title}
      </Link>
    </li>
  );
}

export default memo(NavLink);
