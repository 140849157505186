import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actionCreators as vehiclesActions } from '../../../config/redux/vehicles/actions';
import VehicleForm from '../../components/VehicleForm';
import CommonScreen from '../../components/CommonScreen';
import WarningModal from '../../components/WarningModal';

import { HEADERS } from './constants';
import './Vehicles.css';
import FilterVehicleForm from './components/FilterVehicleForm/index.jsx';

function Vehicles() {
  const [show, setShow] = useState(false);
  const [idVehicle, setIdVehicle] = useState(0);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { vehicles, vehiclesLoading } = useSelector(state => state.vehicles);
  const onRemove = id => {
    setIdVehicle(id);
    setShow(true);
  };

  const handlePressOk = () => {
    dispatch(vehiclesActions.deleteVehicle(idVehicle));
    setShow(false);
  };
  const handleCloseModal = () => setShow(false);

  useEffect(() => {
    dispatch(vehiclesActions.getVehicles({ page }));
  }, [dispatch, page]);

  const modalMessage = `¿Esta seguro de eliminar el vehículo ${idVehicle}?`;

  return (
    <React.Fragment>
      <WarningModal
        message={modalMessage}
        handleCloseModal={handleCloseModal}
        onPressOk={handlePressOk}
        show={show}
      />
      <CommonScreen
        title={'Vehículos'}
        buttonTitle={'Nuevo Vehículo'}
        ComponentForm={VehicleForm}
        Filter={FilterVehicleForm}
        headers={HEADERS}
        data={vehicles || []}
        onRemove={onRemove}
        loading={vehiclesLoading}
        setPage={setPage}
        page={page}
      />
    </React.Fragment>
  );
}

export default Vehicles;
