import { completeTypes, createTypes } from 'redux-recompose';

import { getPermissions } from '../../services/permissions';

export const actions = createTypes(
  completeTypes({
    primaryActions: ['GET_PERMISSIONS']
  }),
  '@@PERMISSIONS'
);

const TARGETS = {
  PERMISSIONS: 'permissions'
};

export const actionCreators = {
  getPermissions: userId => ({
    type: actions.GET_PERMISSIONS,
    target: TARGETS.PERMISSIONS,
    payload: userId,
    service: getPermissions
  })
};
