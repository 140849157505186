import React from 'react';

import SelectControled from '../SelectControled';

import { MONTHS } from './constants';

function SelectMonthControlled({ ...props }) {
  const renderMonths = MONTHS.map(month => (
    <option key={month.value} value={month.value}>
      {month.label}
    </option>
  ));
  return <SelectControled options={renderMonths} defaultValue={0} {...props} />;
}

export default SelectMonthControlled;
