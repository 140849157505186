import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { configureMergeState, fetchMiddleware } from 'redux-recompose';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { devToolsEnhancer } from 'redux-devtools-extension';
import {
  seamlessImmutableReconciler,
  seamlessImmutableTransformCreator
} from 'redux-persist-seamless-immutable';

import userReducer from './user/reducer';
import vehiclesReducer from './vehicles/reducer';
import driversReducer from './drivers/reducer';
import customerReducers from './customers/reducer';
import priceReducer from './prices/reducer';
import appReducer from './appReducer/reducer';
import ticketsReducer from './tickets/reducer';
import spreadsheetsReducer from './spreadsheets/reducer';
import paymentsReducer from './payments/reducer';
import remittancesReducer from './remittances/reducer';
import reviewsReducer from './reviews/reducer';
import permissionsReducer from './permissions/reducer';
import companiesReducer from './companies/reducer';

const transformerConfig = {
  whitelistPerReducer: {
    user: ['currentUser'],
    permissions: ['permissions']
  }
};

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: seamlessImmutableReconciler,
  transforms: [seamlessImmutableTransformCreator(transformerConfig)]
};

configureMergeState((state, diff) => state.merge(diff));

const reducers = combineReducers({
  user: userReducer,
  vehicles: vehiclesReducer,
  drivers: driversReducer,
  customers: customerReducers,
  prices: priceReducer,
  app: appReducer,
  tickets: ticketsReducer,
  spreadsheets: spreadsheetsReducer,
  payments: paymentsReducer,
  remittances: remittancesReducer,
  reviews: reviewsReducer,
  permissions: permissionsReducer,
  companies: companiesReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

const middlewares = [];
const enhancers = [];

middlewares.push(thunk);

middlewares.push(fetchMiddleware);

enhancers.push(applyMiddleware(...middlewares));
enhancers.push(devToolsEnhancer({ realtime: true, port: 8000 }));

const store = createStore(reducers, compose(...enhancers));

export default store;
