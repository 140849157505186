import {
  completeReducer,
  completeState,
  createReducer,
  onReadValue
} from 'redux-recompose';
import Immutable from 'seamless-immutable';

import { actions } from './actions';

const stateDescription = {
  description: {
    appLoading: false
  }
};

export const initialState = completeState(stateDescription);

const reducerDescription = {
  primaryActions: [],
  override: {
    [actions.SET_APP_LOADING]: onReadValue()
  }
};

export default createReducer(
  Immutable(initialState),
  completeReducer(reducerDescription)
);
