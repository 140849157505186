import {
  completeReducer,
  completeState,
  createReducer,
  onReadValue
} from 'redux-recompose';
import Immutable from 'seamless-immutable';

import { actions } from './actions';

const stateDescription = {
  description: {
    spreadsheets: [],
    lastSpreadsheet: null
  }
};

export const initialState = completeState(stateDescription);

const reducerDescription = {
  primaryActions: [actions.GET_SPREADSHEETS, actions.ADD_SPREADSHEET],
  override: {
    [actions.SET_LAST_SPREADSHEET]: onReadValue()
  }
};

export default createReducer(
  Immutable(initialState),
  completeReducer(reducerDescription)
);
