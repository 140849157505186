import React from 'react';
import Modal from 'react-bootstrap/Modal';

import CustomButton from '../../components/CustomButton';
import warningRed from '../../assets/warningRed.png';

import './WarningModal.css';

function WarningModal({ show, handleCloseModal, message, onPressOk }) {
  return (
    <React.Fragment>
      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Body>
          <div className="modal-container">
            <img src={warningRed} alt="Warning" className="img-warning" />
            <p>{message}</p>
            <div>
              <CustomButton type={'button'} onClick={onPressOk}>
                Aceptar
              </CustomButton>
              <CustomButton
                type={'button'}
                buttonStyle={'btn--outline'}
                onClick={handleCloseModal}
              >
                Cancelar
              </CustomButton>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default WarningModal;
