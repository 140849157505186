import React from 'react';

import SelectControled from '../SelectControled';

function SelectCitiesPrice({ prices, name, ...props }) {
  const cities = prices.length ? prices.map(price => price[name]) : [];
  const citiesFiltered = cities.filter(
    (item, index) => cities.indexOf(item) === index
  );

  const renderCitiesOptions = cities =>
    cities.map(city => (
      <option value={city} key={city}>
        {`${city}`}
      </option>
    ));
  return (
    <SelectControled
      name={name}
      title={name?.toUpperCase()}
      options={citiesFiltered && renderCitiesOptions(citiesFiltered)}
      {...props}
    />
  );
}

export default SelectCitiesPrice;
