import React, { useState } from 'react';

import CustomTextInput from '../CustomTextInput';
import CustomButton from '../CustomButton';
import useLoading from '../../../hooks/useLoading';
import { getCustomerData } from '../../../config/services/customers';
import CustomerForm from '../CustomerForm';

function SelectCustomerForm({
  getValues,
  errors,
  register,
  setValue,
  title,
  name,
  phone,
  idCliente = 'cliente',
  customIdLabel = 'CÉDULA'
}) {
  const commonProps = { errors, register };
  const [showForm, setShowForm] = useState(false);
  const [searchingCustomer, setsearchingCustomer] = useState(false);
  const searchCustomer = async () => {
    const idCustomer = getValues(idCliente);
    if (idCustomer !== '') {
      setsearchingCustomer(true);
      const customerData = await getCustomerData(idCustomer);
      setValue(name, customerData?.data?.nombre ?? '');
      setValue(phone, customerData?.data?.telefono ?? '');
      setsearchingCustomer(false);
    } else {
      setValue(name, '');
      setValue(phone, '');
    }
  };
  useLoading(searchingCustomer);
  const handleOpenModal = () => setShowForm(true);
  const handleCloseModal = () => setShowForm(false);
  return (
    <div className="cliente-container">
      <CustomerForm show={showForm} handleClose={handleCloseModal} />
      <h3>{title}</h3>
      <hr />
      <div className="row g-4">
        <CustomTextInput
          name={idCliente}
          required
          title={customIdLabel}
          {...commonProps}
          column="col-md-2"
          onBlur={searchCustomer}
        />
        <CustomTextInput
          name={name}
          required
          {...commonProps}
          title={'NOMBRE'}
          column="col-md-6"
          readOnly
        />
        <CustomTextInput
          name={phone}
          required
          {...commonProps}
          title={'TELEFONO'}
          column="col-md-2"
          readOnly
        />
        <div className="col-md-2">
          <CustomButton type={'button'} onClick={handleOpenModal}>
            Nuevo cliente
          </CustomButton>
        </div>
      </div>
    </div>
  );
}

export default SelectCustomerForm;
