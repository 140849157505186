export const getFormattedCurrentDate = () => {
  const date = new Date();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  const year = date.getFullYear();

  if (day < 10) day = '0' + day;
  if (month < 10) month = '0' + month;

  return `${year}-${month}-${day}`;
};

export const formattedDate = date => {
  const formatted = new Date(date);
  let month = formatted.getMonth() + 1;
  let day = formatted.getDate();
  const year = formatted.getFullYear();

  if (day < 10) day = '0' + day;
  if (month < 10) month = '0' + month;

  return `${year}-${month}-${day}`;
};
