import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PrintSpreadsheet } from '../../components/PrintSpreadsheet';
import CustomButton from '../../components/CustomButton';

function PrintSpreadsheetScreen() {
  const componentRef = useRef();
  const navigate = useNavigate();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });
  const handleNewSpreadsheet = () => navigate('/crear-planilla');
  const { lastSpreadsheet } = useSelector(state => state.spreadsheets);
  return (
    <React.Fragment>
      <PrintSpreadsheet ref={componentRef} {...lastSpreadsheet} />
      <CustomButton
        type={'button'}
        buttonStyle="btn--primary"
        onClick={handlePrint}>
        Imprimir
      </CustomButton>
      <CustomButton
        type={'button'}
        buttonStyle="btn--primary"
        onClick={handleNewSpreadsheet}>
        Nueva
      </CustomButton>
    </React.Fragment>
  );
}

export default PrintSpreadsheetScreen;
