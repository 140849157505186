import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';

import EmptyTable from '../../../../components/EmptyTable';
import CustomButton from '../../../../components/CustomButton';

function ActivedTicketsTable({
  tickets: { data },
  spreadsheetTickets,
  setSpreadSheetTickets,
  handleClose
}) {
  const HEADERS = [
    { title: 'TIQUETE' },
    { title: 'CÉDULA' },
    { title: 'NOMBRE' },
    { title: 'TELÉFONO' },
    { title: 'PUESTOS' },
    { title: 'USUARIO' },
    { title: 'VALOR' }
  ];
  const [selectedTickets, setSelectedTickets] = useState([]);
  const columnsHeader = HEADERS.map((item, index) => (
    <th key={index}>{item.title}</th>
  ));
  const rowsBody = data.map(item => {
    const handleChangeSelected = e => {
      if (e.target.checked) {
        const newArray = selectedTickets;
        newArray.push(item);
        setSelectedTickets(newArray);
      } else {
        const newArray = selectedTickets.filter(
          ticket => ticket.id !== item.id
        );
        setSelectedTickets(newArray);
      }
    };
    return (
      <tr key={item.id}>
        <td>{item.id}</td>
        <td>{item.cliente}</td>
        <td>{item.customer.nombre}</td>
        <td>{item.customer.telefono}</td>
        <td>{item.puestos}</td>
        <td>{item.usuario.toUpperCase()}</td>
        <td>{`$ ${item.precio.toLocaleString()}`}</td>
        <td>
          <input type={'checkbox'} onChange={handleChangeSelected} />
        </td>
      </tr>
    );
  });
  const handleAddTickets = () => {
    const newArray = spreadsheetTickets.concat(selectedTickets);
    setSpreadSheetTickets(newArray);
    handleClose();
  };
  return (
    <div>
      {data.length > 0 ? (
        <Table>
          <thead>
            <tr>{columnsHeader}</tr>
          </thead>
          <tbody>{rowsBody}</tbody>
        </Table>
      ) : (
        <EmptyTable />
      )}
      <CustomButton type={'button'} onClick={handleAddTickets}>
        Agregar
      </CustomButton>
      <CustomButton
        type={'button'}
        buttonStyle={'btn--outline'}
        onClick={handleClose}>
        Cancelar
      </CustomButton>
    </div>
  );
}

export default ActivedTicketsTable;
