import { api } from '../api';

const companiesPath = `/companies`;

export const getCompanies = data => api.get(companiesPath);

export const updateCompanies = data =>
  api.put(`${companiesPath}/${data.id}`, data);

export const getCompanyData = id => api.get(`${companiesPath}/${id}`);

export const uploadLogo = data =>
  api.post(`${companiesPath}/upload-logo`, data);
