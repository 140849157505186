import {
  completeTypes,
  createTypes,
  withPostFailure,
  withPostSuccess
} from 'redux-recompose';

import {
  cancelSpreadsheet,
  getSpreadsheets,
  saveSpreadsheet
} from '../../services/spreadsheets';

export const actions = createTypes(
  completeTypes({
    primaryActions: ['GET_SPREADSHEETS', 'ADD_SPREADSHEET'],
    ignoredActions: ['SET_LAST_SPREADSHEET', 'CANCEL_SPREADSHEET']
  }),
  '@@SPREADSHEETS'
);

const TARGETS = {
  SPREADSHEETS: 'spreadsheets',
  LAST_SPREADSHEET: 'lastSpreadsheet'
};

export const actionCreators = {
  getSpreadsheets: data => ({
    type: actions.GET_SPREADSHEETS,
    target: TARGETS.SPREADSHEETS,
    payload: data,
    service: getSpreadsheets
  }),
  addSpreadsheet: (data, navigate) => ({
    type: actions.ADD_SPREADSHEET,
    target: TARGETS.LAST_SPREADSHEET,
    payload: data,
    service: saveSpreadsheet,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionCreators.setLastSpreadsheet(response?.data));
        navigate(`/imprimir-planilla`);
      })
    ]
  }),
  setLastSpreadsheet: data => ({
    type: actions.SET_LAST_SPREADSHEET,
    target: TARGETS.LAST_SPREADSHEET,
    payload: data
  }),
  cancelSpreadsheet: data => ({
    type: actions.CANCEL_SPREADSHEET,
    payload: data,
    service: cancelSpreadsheet,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionCreators.getSpreadsheets({ page: 1 }));
      }),
      withPostFailure((_dispatch, _response) => {
        alert('No se pudo anular esta planilla');
      })
    ]
  })
};
