import { api } from '../api';

const DriversPath = '/drivers';

export const getDrivers = data =>
  api.post(`${DriversPath}/filter?page=${data.page}`, data);

export const saveDriver = data => api.post(`${DriversPath}`, data);

export const deleteDriver = id => api.delete(`${DriversPath}/${id}`);

export const updateConductor = data =>
  api.put(`${DriversPath}/${data.id}`, data);

export const getDriverData = id => api.get(`${DriversPath}/${id}`);
