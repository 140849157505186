import React from 'react';

function ReviewRow({
  id,
  vehiculo,
  vehicle,
  usuario,
  estado,
  created_at,
  descripcion
}) {
  const createdDate = new Date(created_at);
  return (
    <tr key={id}>
      <td align="center">{id}</td>
      <td align="center">{vehiculo}</td>
      <td align="center">{vehicle.placa}</td>
      <td align="center">{usuario}</td>
      <td align="center">{createdDate.toLocaleString()}</td>
      <td align="center">{estado}</td>
      <td>{descripcion}</td>
    </tr>
  );
}

export default ReviewRow;
