import React from 'react';

import './SelectControled.css';

function SelectControled({
  name,
  errors,
  required = false,
  register,
  title,
  column = false,
  options,
  ...props
}) {
  return (
    <div className={`text-input-container ${column && 'col-auto'}`}>
      {title && (
        <label className="label">
          {' '}
          {title}
          <span className="required">{required && ' *'}</span>
        </label>
      )}
      <select
        {...register(name, { required })}
        className="form-control"
        {...props}>
        <option value={''}>Seleccione una opción</option>
        {options}
      </select>
      {errors[name] && <span className="error">Este campo es obligatorio</span>}
    </div>
  );
}

export default SelectControled;
