function Signup() {
  return (
    <div>
      <h1>Signup</h1>
      <h2>Registrarse</h2>
    </div>
  );
}

export default Signup;
