import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import CustomButton from '../../components/CustomButton';
import SelectVehicle from '../../components/SelectVehicle';
import { actionCreators as reviewsActions } from '../../../config/redux/reviews/actions';
import SelectControled from '../../components/SelectControled';
import CustomTextInput from '../../components/CustomTextInput';
import useLoading from '../../../hooks/useLoading';

import './ReviewVehicle.css';

function ReviewVehicle() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset
  } = useForm();
  const dispatch = useDispatch();
  const { currentUser } = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const onSuccess = () => {
    reset();
    setLoading(false);
    alert('Se guardó satisfactoriamente');
  };
  const onFailure = () => {
    setLoading(false);
    alert('Error al guardar');
  };
  const onSubmit = data => {
    setLoading(true);
    dispatch(reviewsActions.addReview(data, onSuccess, onFailure));
  };
  useLoading(loading);

  const commonProps = { errors, register };
  const handleCancel = () => reset();
  const options = [
    { text: 'ACTIVO', value: 'ACTIVO' },
    { text: 'INACTIVO', value: 'INACTIVO' }
  ];
  const renderOptions = options.map(option => (
    <option value={option.value} key={option.value}>{`${option.text}`}</option>
  ));
  return (
    <div>
      <h2>Estado de Vehiculo</h2>
      <div>
        <form id="viewerId" onSubmit={handleSubmit(onSubmit)}>
          <SelectVehicle
            commonProps={commonProps}
            setValue={setValue}
            getValues={getValues}
            required
          />
          <CustomTextInput
            name={'usuario'}
            type={'hidden'}
            value={currentUser.name}
            {...commonProps}
          />
          <div className="row">
            <SelectControled
              name={'estado'}
              {...commonProps}
              required
              title={'ESTADO'}
              options={renderOptions}
            />
          </div>
          <div className="textarea-container">
            <label className="form-label">Estado del vehiculo</label>
            <textarea
              className="form-control"
              rows={3}
              {...register('descripcion')}></textarea>
          </div>
          <div className="button-container">
            <CustomButton type={'submit'} buttonStyle="btn--primary">
              Guardar
            </CustomButton>
            <CustomButton
              type={'button'}
              buttonStyle="btn--outline"
              onClick={handleCancel}>
              Cancelar
            </CustomButton>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ReviewVehicle;
