import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';

import { actionCreators as vehiclesActions } from '../../config/redux/vehicles/actions';

import CustomTextInput from './CustomTextInput';
import CustomButton from './CustomButton';
import SelectControled from './SelectControled';
import './ModalForm.css';

function VehicleForm({ item = null, show, handleClose }) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset
  } = useForm();
  const onSubmit = data => {
    dispatch(
      item.id
        ? vehiclesActions.updateVehicle(data)
        : vehiclesActions.addVehicle(data)
    );
    reset();
    handleClose();
  };

  const options = [
    { text: 'ACTIVO', value: 'ACTIVO' },
    { text: 'INACTIVO', value: 'INACTIVO' }
  ];

  const renderOptions = options.map(option => (
    <option value={option.value} key={option.value}>{`${option.text}`}</option>
  ));

  useEffect(() => {
    if (item?.id) {
      Object.entries(item).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [item, setValue]);

  const commonProps = { errors, register };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)} id="form-vehicle">
          <CustomTextInput
            name={'id'}
            title={'VEHICULO'}
            {...commonProps}
            required
            readOnly={item?.id}
          />
          <CustomTextInput
            name={'placa'}
            required
            {...commonProps}
            title={'PLACA'}
          />
          <CustomTextInput
            name={'marca'}
            required
            {...commonProps}
            title={'MARCA'}
          />
          <CustomTextInput name={'modelo'} {...commonProps} title={'MODELO'} />
          <CustomTextInput
            name={'pasajeros'}
            required
            {...commonProps}
            type={'number'}
            min={4}
            defaultValue={4}
            max={50}
            title={'PASAJEROS'}
          />
          <CustomTextInput
            name={'vencimiento_soat'}
            {...commonProps}
            required
            type={'date'}
            title={'FECHA VENCIMIENTO SOAT'}
          />
          <CustomTextInput
            name={'vencimiento_tec_mec'}
            {...commonProps}
            required
            type={'date'}
            title={'FECHA VENCIMIENTO TECNICO MECANICA'}
          />
          <CustomTextInput
            name={'vencimiento_todo_riesgo'}
            {...commonProps}
            required
            type={'date'}
            title={'FECHA VENCIMIENTO TODO RIESGO'}
          />
          <CustomTextInput
            name={'vencimiento_tarjeta_operacion'}
            {...commonProps}
            required
            type={'date'}
            title={'FECHA VENCIMIENTO TARJETA OPERACIÓN'}
          />
          <SelectControled
            name={'estado'}
            {...commonProps}
            required
            title={'ESTADO'}
            options={renderOptions}
          />
          <div className="btn-group">
            <CustomButton type={'submit'}>Guardar</CustomButton>
            <CustomButton
              type={'button'}
              onClick={handleClose}
              buttonStyle={'btn--outline'}>
              Cancelar
            </CustomButton>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default VehicleForm;
