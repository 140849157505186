import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PrintRemittance } from '../../components/PrintRemittance';
import CustomButton from '../../components/CustomButton';

function PrintRemittanceScreen() {
  const componentRef = useRef();
  const navigate = useNavigate();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });
  const handleNewRemittance = () => navigate('/nuevaRemesa');
  const { lastRemittance } = useSelector(state => state.remittances);
  return (
    <React.Fragment>
      <PrintRemittance ref={componentRef} {...lastRemittance} />
      <CustomButton
        type={'button'}
        buttonStyle="btn--primary"
        onClick={handlePrint}>
        Imprimir
      </CustomButton>
      <CustomButton
        type={'button'}
        buttonStyle="btn--primary"
        onClick={handleNewRemittance}>
        Nuevo
      </CustomButton>
    </React.Fragment>
  );
}

export default PrintRemittanceScreen;
