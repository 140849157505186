import React from 'react';
import { useSelector } from 'react-redux';

import logoDefault from '../../assets/default.png';

import './PrintHeader.css';

function PrintHeader() {
  const { companies } = useSelector(state => state.companies);
  const currentCompany = companies.length > 0 ? companies[0] : null;

  return (
    <>
      <div className="logo-container">
        <img
          src={currentCompany.logo || logoDefault}
          alt="logo"
          className="logo-print"
        />
      </div>
      {currentCompany && (
        <>
          <p className="subtitle">{currentCompany.name}</p>
          <p className="subtitle">{`Nit: ${currentCompany.nit}`}</p>
          <p className="title">{currentCompany.alias}</p>
          <p className="text">{currentCompany.address}</p>
          <p className="text">{currentCompany.address2}</p>
        </>
      )}
    </>
  );
}

export default PrintHeader;
