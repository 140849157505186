import React from 'react';

function PaymentRow({
  id,
  vehicle_id,
  vehicle,
  month,
  year,
  status,
  created_at
}) {
  const createdDate = new Date(created_at);
  const MONTHS = [
    'ENERO',
    'FEBRERO',
    'MARZO',
    'ABRIL',
    'MAYO',
    'JUNIO',
    'JULIO',
    'AGOSTO',
    'SEPTIEMBRE',
    'OCTUBRE',
    'NOVIEMBRE',
    'DICIEMBRE'
  ];
  return (
    <tr key={id}>
      <td align="center">{id}</td>
      <td align="center">{vehicle_id}</td>
      <td align="center">{vehicle.placa}</td>
      <td align="center">{MONTHS[month - 1]}</td>
      <td align="center">{year}</td>
      <td align="center">{createdDate.toLocaleString()}</td>
      <td align="center">{status}</td>
      {/* <td>
        <FontAwesomeIcon icon={faBan} onClick={null} className={'icon'} />
      </td> */}
    </tr>
  );
}

export default PaymentRow;
