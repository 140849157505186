import {
  completeTypes,
  createTypes,
  withPostFailure,
  withPostSuccess
} from 'redux-recompose';

import {
  saveRemittance,
  getRemittances,
  deleteRemittance
} from '../../services/remittances';

export const actions = createTypes(
  completeTypes({
    primaryActions: ['GET_REMITTANCES', 'ADD_REMITTANCE'],
    ignoredActions: ['SET_LAST_REMITTANCE', 'DELETE_REMITTANCE']
  }),
  '@@REMITTANCES'
);

const TARGETS = {
  REMITTANCES: 'remittances',
  LAST_REMITTANCE: 'lastRemittance'
};

export const actionCreators = {
  getRemittances: payload => ({
    type: actions.GET_REMITTANCES,
    target: TARGETS.REMITTANCES,
    service: getRemittances,
    payload
  }),
  addRemittance: (payload, navigate) => ({
    type: actions.ADD_REMITTANCE,
    target: TARGETS.LAST_REMITTANCE,
    service: saveRemittance,
    payload,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionCreators.setLastRemittance(response?.data));
        navigate(`/imprimir-remesa`);
      }),
      withPostFailure((_dispatch, _response) => {
        alert('No se pudo guardar esta remesa');
      })
    ]
  }),
  setLastRemittance: data => ({
    type: actions.SET_LAST_REMITTANCE,
    target: TARGETS.LAST_REMITTANCE,
    payload: data
  }),
  deleteRemittance: id => ({
    type: actions.DELETE_REMITTANCE,
    payload: id,
    service: deleteRemittance,
    injections: [
      withPostSuccess((dispatch, _response) => {
        dispatch(actionCreators.getRemittances({ page: 1 }));
      }),
      withPostFailure((_dispatch, _response) => {
        alert('No se pudo anular esta remesa');
      })
    ]
  })
};
