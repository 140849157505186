import React from 'react';
import Table from 'react-bootstrap/Table';

function CommonTable({ columnsHeader, rowsBody, tableId }) {
  return (
    <div>
      <Table className="custom-table" id={tableId}>
        <thead>
          <tr>{columnsHeader}</tr>
        </thead>
        <tbody>{rowsBody}</tbody>
      </Table>
    </div>
  );
}

export default CommonTable;
