import {
  completeReducer,
  completeState,
  createReducer,
  onReadValue
} from 'redux-recompose';
import Immutable from 'seamless-immutable';

import { actions } from './actions';

const stateDescription = {
  description: {
    currentUser: null,
    allUsers: []
  }
};

export const initialState = completeState(stateDescription);

const reducerDescription = {
  primaryActions: [actions.LOGIN, actions.LOGOUT, actions.GET_USERS],
  override: {
    [actions.REMOVE_CURRENT_USER]: onReadValue(),
    [actions.SET_CURRENT_USER_LOADING]: onReadValue(),
    [actions.SET_CURRENT_USER]: onReadValue()
  }
};

export default createReducer(
  Immutable(initialState),
  completeReducer(reducerDescription)
);
