import React, { useMemo, useState } from 'react';
import Table from 'react-bootstrap/Table';

import TablePagination from '../TablePagination';
import WarningModal from '../WarningModal';

import './SpreadsheetsTable.css';
import RowItem from './components/RowItem';
import { useCancelSpreadsheet } from './useCancelSpreadsheet';

const HEADERS = [
  { title: 'PLANILLA' },
  { title: 'VEHÍCULO' },
  { title: 'CONDUCTOR' },
  { title: 'ORIGEN' },
  { title: 'DESTINO' },
  { title: 'ESTADO' },
  { title: 'USUARIO' },
  { title: 'ADICIONAL' },
  { title: 'FECHA' },
  { title: 'TIQUETES' }
];

function SpreadsheetsTable({
  rows: { data, current_page, last_page },
  tableId,
  hasPermissionEdit,
  page,
  setPage
}) {
  const [show, setShow] = useState(false);
  const [spreadsheetId, setSpreadsheetId] = useState('');

  const columnsHeader = useMemo(
    () => HEADERS.map(item => <th key={item.title}>{item.title}</th>),
    []
  );
  const rowsBody = useMemo(
    () =>
      data?.map(item => (
        <RowItem
          key={item.id}
          item={item}
          setSpreadsheetId={setSpreadsheetId}
          setShow={setShow}
          hasPermissionEdit={hasPermissionEdit}
        />
      )),
    [data, hasPermissionEdit]
  );
  const handlePressOk = useCancelSpreadsheet(spreadsheetId, setShow);

  return (
    <>
      <WarningModal
        message={`¿Esta seguro de anular la planilla número ${spreadsheetId}?`}
        handleCloseModal={() => setShow(false)}
        onPressOk={handlePressOk}
        show={show}
      />
      <Table className="custom-table" id={tableId}>
        <thead>
          <tr>{columnsHeader}</tr>
        </thead>
        <tbody>{rowsBody}</tbody>
      </Table>
      {current_page && last_page && (
        <TablePagination
          currentPage={current_page}
          lastPage={last_page}
          setPage={setPage}
          page={page}
        />
      )}
    </>
  );
}

export default SpreadsheetsTable;
