import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import CustomButton from '../../components/CustomButton';
import CustomTextInput from '../../components/CustomTextInput';
import { actionCreators as paymentsActions } from '../../../config/redux/payments/actions';
import SelectMonthControlled from '../../components/SelectMonthControlled';
import SelectVehicle from '../../components/SelectVehicle';
import useLoading from '../../../hooks/useLoading';
import SelectControled from '../../components/SelectControled';

import './Payment.css';

function Payment() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue
  } = useForm();
  const commonProps = { errors, register };
  const dispatch = useDispatch();
  const handleReset = () => document.getElementById('paymentId').reset();
  const [loading, setLoading] = useState(false);
  const onSuccess = () => {
    handleReset();
    setLoading(false);
    alert('Se generó el pago satisfactoriamente');
  };
  const onFailure = () => {
    setLoading(false);
    alert('Error al guardar el pago');
  };
  const onSubmit = data => {
    setLoading(true);
    dispatch(paymentsActions.addPayment(data, onSuccess, onFailure));
  };
  useLoading(loading);
  const { currentUser } = useSelector(state => state.user);
  const handleCancel = () => handleReset();

  const years = [
    { text: '2022', value: 2022 },
    { text: '2023', value: 2023 },
    { text: '2024', value: 2024 },
    { text: '2025', value: 2025 },
    { text: '2026', value: 2026 },
    { text: '2027', value: 2027 },
    { text: '2028', value: 2028 },
    { text: '2029', value: 2029 },
    { text: '2030', value: 2030 }
  ];
  const renderOptions = years.map(option => (
    <option value={option.value} key={option.value}>{`${option.text}`}</option>
  ));
  return (
    <div>
      <h2>Pago de Vehículo</h2>
      <div>
        <form id="paymentId" onSubmit={handleSubmit(onSubmit)}>
          <SelectVehicle
            commonProps={commonProps}
            setValue={setValue}
            getValues={getValues}
            required
          />
          <div className="row">
            <CustomTextInput
              name={'vencimiento_soat'}
              title={'Vencimiento SOAT'}
              column="col-md-4"
              readOnly
              {...commonProps}
            />
            <CustomTextInput
              name={'vencimiento_tec_mec'}
              title={'Vencimiento tec. mec.'}
              column="col-md-4"
              readOnly
              {...commonProps}
            />
            <CustomTextInput
              name={'vencimiento_todo_riesgo'}
              title={'Vencimiento todo riesgo'}
              column="col-md-4"
              readOnly
              {...commonProps}
            />
          </div>
          <CustomTextInput
            name={'user'}
            type={'hidden'}
            value={currentUser.name}
            {...commonProps}
          />
          <div className="row">
            <CustomTextInput
              name={'estado'}
              title={'Estado'}
              column="col-md-2"
              readOnly
              {...commonProps}
            />
            <CustomTextInput
              name={'razon_estado'}
              title={'Razón de estado'}
              column="col-md-10"
              readOnly
              {...commonProps}
            />
          </div>
          <div className="row">
            <SelectMonthControlled
              name={'month'}
              title={'Mes'}
              {...commonProps}
              column="col-md-6"
              required
            />
            <SelectControled
              name={'year'}
              {...commonProps}
              required
              title={'AÑO'}
              options={renderOptions}
              column="col-md-6"
            />
          </div>
          <div className="button-container">
            <CustomButton type={'submit'} buttonStyle="btn--primary">
              Pago
            </CustomButton>
            <CustomButton
              type={'button'}
              buttonStyle="btn--outline"
              onClick={handleCancel}>
              Cancelar
            </CustomButton>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Payment;
