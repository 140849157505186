import { api } from '../api';

const usersPath = `/users`;

export const login = data => api.post(`${usersPath}/login`, data);

export const logout = () => api.get(`${usersPath}/logout`);

export const saveUser = data => api.post(`${usersPath}/register`, data);

export const getUsers = () => api.get(`${usersPath}`);

export const deleteUser = id => api.delete(`${usersPath}/${id}`);

export const updateUser = data => api.put(`${usersPath}/${data.id}`, data);
