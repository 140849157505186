import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { actionCreators as ticketsActions } from '../../../config/redux/tickets/actions';
import CustomTextInput from '../../components/CustomTextInput';
import SelectCitiesPrice from '../../components/SelectCitiesPrice';
import CustomButton from '../../components/CustomButton';
import useLoading from '../../../hooks/useLoading';
import SelectCustomerForm from '../../components/SelectCustomerForm';

import './SaleTicket.css';

function SaleTicket() {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    control
  } = useForm();
  const { lastTicketLoading } = useSelector(state => state.tickets);
  const navigate = useNavigate();
  const onSubmit = data => {
    dispatch(ticketsActions.addTicket(data, navigate));
  };
  const dispatch = useDispatch();
  const { prices } = useSelector(state => state.prices);

  const commonProps = { errors, register };
  const handleCancel = () => document.getElementById('ticketFormId').reset();
  useLoading(lastTicketLoading);
  const currentUser = useSelector(state => state.user.currentUser);
  const handleCalculate = () => {
    const origen = getValues('origen');
    const destino = getValues('destino');
    const puestos = getValues('puestos');
    if (origen && destino) {
      const price = prices.find(
        element => element.origen === origen && element.destino === destino
      );
      const total = price.precio * puestos;
      setValue('precio', total);
      setValue('costo', `$ ${total.toLocaleString('es-ES')}`);
    }
  };
  const selecteProps = {
    required: true,
    column: 'col-md-3',
    prices,
    control,
    ...commonProps
  };

  const handleClose = () => navigate('/');

  return (
    <div>
      <h1>Nuevo Tiquete</h1>
      <form onSubmit={handleSubmit(onSubmit)} id="ticketFormId">
        <SelectCustomerForm
          title={'Cliente'}
          getValues={getValues}
          setValue={setValue}
          name={'nombre'}
          phone={'telefono'}
          {...commonProps}
        />
        <div className="inputs-container">
          <h3>Ruta</h3>
          <hr />
          <div className="row g-3">
            <CustomTextInput
              name={'puestos'}
              required
              {...commonProps}
              title={'PUESTOS'}
              column="col-md-3"
              type={'number'}
              min={1}
              defaultValue={1}
              max={10}
            />
            <SelectCitiesPrice name={'origen'} {...selecteProps} />
            <SelectCitiesPrice name={'destino'} {...selecteProps} />
            <div className="col-md-2">
              <CustomButton type={'button'} onClick={handleCalculate}>
                Calcular
              </CustomButton>
            </div>
            <CustomTextInput
              name={'costo'}
              readOnly
              required
              {...commonProps}
              title={'VALOR'}
              column="col-md-3"
            />
          </div>
          <CustomTextInput name={'precio'} {...commonProps} type={'hidden'} />
        </div>
        <div className="inputs-container">
          <h3>Fecha y Hora</h3>
          <hr />
          <div className="row g-3">
            <CustomTextInput
              name={'fecha'}
              type={'date'}
              required
              {...commonProps}
              title={'FECHA'}
              column="col-md-3"
            />
          </div>
        </div>
        <div className="inputs-container">
          <h3>Observaciones</h3>
          <hr />
          <div className="row g-3">
            <CustomTextInput
              name={'observaciones'}
              {...commonProps}
              column="col-md-4"
            />
            <CustomTextInput
              name={'usuario'}
              type={'hidden'}
              value={currentUser.name}
              {...commonProps}
              column="col-md-4"
            />
          </div>
        </div>
        <div className="btn-group">
          <CustomButton type={'submit'}>Guardar</CustomButton>
          <CustomButton
            type={'button'}
            buttonStyle="btn--outline"
            onClick={handleCancel}>
            Cancelar
          </CustomButton>
          <CustomButton
            type={'button'}
            buttonStyle="btn--outline"
            onClick={handleClose}>
            Salir
          </CustomButton>
        </div>
      </form>
    </div>
  );
}

export default SaleTicket;
