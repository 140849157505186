import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Navbar from '../components/Navbar';
import { actionCreators as pricesActions } from '../../config/redux/prices/actions';
import { actionCreators as companiesActions } from '../../config/redux/companies/actions';
import useLoading from '../../hooks/useLoading';

function App() {
  const dispatch = useDispatch();

  const { pricesLoading, companiesLoading } = useSelector(state => ({
    pricesLoading: state.prices.pricesLoading,
    companiesLoading: state.companies.companiesLoading
  }));

  useEffect(() => {
    dispatch(pricesActions.getPrices());
    dispatch(companiesActions.getCompanies());
  }, [dispatch]);

  useLoading(pricesLoading || companiesLoading);
  return (
    <>
      <Navbar />
      <section className="container app-container">
        <Outlet />
      </section>
    </>
  );
}

export default App;
