import React from 'react';

import PrintHeader from '../PrintHeader';

import './PrintRemittance.css';

export const PrintRemittance = React.forwardRef(
  (
    {
      id,
      created_at,
      origen,
      destino,
      usuario,
      valor,
      customer_destinatario,
      direccion_destinatario,
      direccion_remitente,
      customer_remitente,
      contenido,
      puerta,
      vehiculo,
      estado,
      vehicle
    },
    ref
  ) => {
    const generateDate = new Date(created_at);
    return (
      <div ref={ref} className="remittance-container">
        <PrintHeader />
        <span className="ticket-anulado">{estado === 'ANULADA' && estado}</span>
        <hr />
        <table>
          <tr className="tr-border">
            <td>Remesa No:</td>
            <td align="right">
              <strong>{id}</strong>
            </td>
          </tr>
        </table>
        Fecha y Hora:
        <p className="clear-space">
          <strong>{generateDate.toLocaleString()}</strong>
        </p>
        Origen:
        <p className="clear-space">
          <strong>{origen}</strong>
        </p>
        Destino:
        <p className="clear-space">
          <strong>{destino}</strong>
        </p>
        Valor:
        <p className="clear-space">
          <strong>{valor}</strong>
        </p>
        {vehiculo && (
          <>
            <p className="clear-space">Vehículo que transporta (Placa):</p>
            <p className="clear-space">
              <strong>{`${vehiculo} (${vehicle.placa})`}</strong>
            </p>
          </>
        )}
        <hr />
        Nit o cédula destinatario:
        <p className="clear-space">
          <strong>{customer_destinatario.id}</strong>
        </p>
        Destinatario:
        <p className="clear-space">
          <strong>{customer_destinatario.nombre}</strong>
        </p>
        Dirección destinatario:
        <p className="clear-space">
          <strong>{direccion_destinatario}</strong>
        </p>
        {puerta && (
          <p className="clear-space">
            <strong>Entregar en la dirección del destinatario</strong>
          </p>
        )}
        Telefono destinatario:
        <p className="clear-space">
          <strong>{customer_destinatario.telefono}</strong>
        </p>
        <hr />
        Nit o cédula remitente:
        <p className="clear-space">
          <strong>{customer_remitente.id}</strong>
        </p>
        Remitente:
        <p className="clear-space">
          <strong>{customer_remitente.nombre}</strong>
        </p>
        Dirección remitente:
        <p className="clear-space">
          <strong>{direccion_remitente}</strong>
        </p>
        Telefono remitente:
        <p className="clear-space">
          <strong>{customer_remitente.telefono}</strong>
        </p>
        Contenido:
        <p className="clear-space">
          <strong>{contenido}</strong>
        </p>
        <div className="observaciones text-footer">
          <p className="clear-space">{`Vendedor: ${usuario}`}</p>
          <p className="clear-space">{`Impreso por software transporte`}</p>
        </div>
      </div>
    );
  }
);
