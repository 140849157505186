import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actionCreators as pricesActions } from '../../../config/redux/prices/actions';
import PriceForm from '../../components/PriceForm';
import CommonScreen from '../../components/CommonScreen';
import WarningModal from '../../components/WarningModal';

function Prices() {
  const headers = [
    { title: 'ID' },
    { title: 'ORIGEN' },
    { title: 'DESTINO' },
    { title: 'PRECIO' }
  ];
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [idPrice, setIdPrice] = useState('');
  const { prices, pricesLoading } = useSelector(state => state.prices);

  const onRemove = id => {
    setIdPrice(id);
    setShow(true);
  };

  const handleCloseModal = () => setShow(false);

  const handlePressOk = () => {
    dispatch(pricesActions.deletePrice(idPrice));
    setShow(false);
  };

  const modalMessage = `¿Esta seguro de eliminar esta tarifa?`;

  return (
    <React.Fragment>
      <WarningModal
        message={modalMessage}
        handleCloseModal={handleCloseModal}
        onPressOk={handlePressOk}
        show={show}
      />
      <CommonScreen
        title={'Tarifas'}
        buttonTitle={'Nueva Tarifa'}
        ComponentForm={PriceForm}
        headers={headers}
        data={prices}
        onRemove={onRemove}
        loading={pricesLoading}
      />
    </React.Fragment>
  );
}

export default Prices;
