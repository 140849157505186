import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { getCompanyData } from '../../../config/services/companies';
import CustomTextInput from '../../components/CustomTextInput';
import CurrencyInput from '../../components/CurrencyInput';
import CustomButton from '../../components/CustomButton';
import { actionCreators as companiesActions } from '../../../config/redux/companies/actions';
import useLoading from '../../../hooks/useLoading';
import { usePermissions } from '../../../hooks/usePermissions';
import imageDefault from '../../assets/default.png';

import './Company.css';
import SelectFile from './components/SelectFile';

function Company() {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control
  } = useForm();
  const [currentCompany, setCurrentCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [logo, setLogo] = useState(currentCompany?.logo);
  const hasPermissionEdit = usePermissions();
  const commonProps = { errors, register, readOnly: !hasPermissionEdit };
  useLoading(loading);

  const getCurrentCompany = useCallback(async () => {
    const company = await getCompanyData(1);
    if (company.ok) {
      setCurrentCompany(company.data);
    } else {
      alert('Error al obtener los datos de la compañia');
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getCurrentCompany();
  }, [getCurrentCompany]);

  const onSubmit = data => {
    data.price =
      data.price && typeof data.price === 'string'
        ? data.price.replace('$', '').replace('.', '')
        : '0';
    dispatch(companiesActions.updateCompanies(data));
  };

  useEffect(() => {
    if (currentCompany) {
      Object.entries(currentCompany).forEach(([key, value]) => {
        setValue(key, value);
      });
      setLogo(currentCompany.logo);
    }
  }, [currentCompany, setValue]);

  const setUrlLogo = url => {
    setValue('logo', url);
    setLogo(url);
  };

  return (
    <>
      <SelectFile
        show={showModal}
        handleClose={() => setShowModal(false)}
        setUrlLogo={setUrlLogo}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomTextInput name={'id'} {...commonProps} type={'hidden'} />
        <div className="logo-container">
          <img
            src={logo || imageDefault}
            width={100}
            alt="Imagen para impresión de tikets y planillas"
          />
          <CustomTextInput name={'logo'} title={'LOGO'} {...commonProps} />
          {hasPermissionEdit && (
            <CustomButton type={'button'} onClick={() => setShowModal(true)}>
              Cambiar
            </CustomButton>
          )}
        </div>
        <CustomTextInput name={'nit'} title={'NIT'} {...commonProps} required />
        <CustomTextInput
          name={'name'}
          required
          {...commonProps}
          title={'NOMBRE COMPLETO'}
        />
        <CustomTextInput
          name={'alias'}
          required
          {...commonProps}
          title={'NOMBRE'}
        />
        <CustomTextInput
          name={'address'}
          {...commonProps}
          title={'DIRECCIÓN'}
        />
        <CustomTextInput
          name={'address2'}
          {...commonProps}
          title={'DIRECCIÓN 2'}
        />
        <div className={`text-input-container col-md-3`}>
          <label className={`label`}>{'PRECIO PLANILLA'}</label>
          <span className="required">{' *'}</span>

          <Controller
            control={control}
            name={'price'}
            {...commonProps}
            render={({ field }) => (
              <CurrencyInput
                {...field}
                className={`form-control`}
                readOnly={!hasPermissionEdit}
              />
            )}
          />
          {errors['price'] && (
            <span className="error">{'Este campo es obligatorio'}</span>
          )}
        </div>
        <CustomTextInput
          name={'insurance'}
          {...commonProps}
          title={'ASEGURADORA'}
        />
        <div className="btn-group">
          {hasPermissionEdit && (
            <CustomButton type={'submit'}>Actualizar</CustomButton>
          )}
        </div>
      </form>
    </>
  );
}

export default Company;
