import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { actionCreators as userActions } from '../../../config/redux/user/actions';
import UserForm from '../../components/UserForm';
import CommonScreen from '../../components/CommonScreen';
import WarningModal from '../../components/WarningModal';
import useLoading from '../../../hooks/useLoading';

import './Users.css';

const HEADERS = [
  { title: 'CÉDULA' },
  { title: 'USUARIO' },
  { title: 'NOMBRE COMPLETO' }
];

function Users() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [idUser, setIdUser] = useState(0);
  const dispatch = useDispatch();
  const { allUsers, allUsersLoading } = useSelector(state => state.user);
  const onRemove = id => {
    setIdUser(id);
    setShow(true);
  };

  const changePermissions = (userId, userName) =>
    navigate('/user-permissions', { state: { userId, userName } });

  const handlePressOk = () => {
    dispatch(userActions.deleteUser(idUser));
    setShow(false);
  };
  const handleCloseModal = () => setShow(false);

  useEffect(() => {
    dispatch(userActions.getUsers());
  }, [dispatch]);

  useLoading(allUsersLoading);

  const modalMessage = `¿Esta seguro de eliminar el usuario con cédula ${idUser}?`;
  return (
    <React.Fragment>
      <WarningModal
        message={modalMessage}
        handleCloseModal={handleCloseModal}
        onPressOk={handlePressOk}
        show={show}
      />
      <CommonScreen
        title={'Usuarios'}
        buttonTitle={'Nuevo Usuario'}
        ComponentForm={UserForm}
        headers={HEADERS}
        data={allUsers}
        onRemove={onRemove}
        loading={allUsersLoading}
        changePermissions={changePermissions}
      />
    </React.Fragment>
  );
}

export default Users;
