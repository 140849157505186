import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { actionCreators } from '../../../config/redux/spreadsheets/actions';

export function useCancelSpreadsheet(spreadsheetId, setShow) {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(actionCreators.cancelSpreadsheet(spreadsheetId));
    setShow(false);
  }, [dispatch, spreadsheetId, setShow]);
}
