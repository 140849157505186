import User from '../../app/screens/User';
import Vehicles from '../../app/screens/Vehicles';
import Drivers from '../../app/screens/Drivers';
import Customers from '../../app/screens/Customers';
import Prices from '../../app/screens/Prices';
import SaleTicket from '../../app/screens/SaleTicket';
import Remittance from '../../app/screens/Remittance';
import Payment from '../../app/screens/Payment';
import ReviewVehicle from '../../app/screens/ReviewVehicle';
import Spreadsheets from '../../app/screens/Spreadsheets';
import InitRoute from '../../app/screens/InitRoute';
import PrintTicketScreen from '../../app/screens/PrintTicketScreen';
import CreateSpreadsheet from '../../app/screens/CreateSpreadsheet';
import Tickets from '../../app/screens/Tickets';
import PrintSpreadsheetScreen from '../../app/screens/PrintSpreadsheetScreen';
import Users from '../../app/screens/Users';
import PaymentsList from '../../app/screens/PaymentsList';
import PrintRemittanceScreen from '../../app/screens/PrintRemittanceScreen';
import RemittancesList from '../../app/screens/RemittancesList';
import ReviewsList from '../../app/screens/ReviewsList';
import Company from '../../app/screens/Company';
import UserPermissions from '../../app/screens/UserPermissions';
import PrintTicketWithIdScreen from '../../app/screens/PrintTicketWithIdScreen';

export const USER_ROUTES = [
  { path: 'usuario', Element: User },
  { path: 'usuarios', Element: Users },
  { path: 'conductores', Element: Drivers },
  { path: 'vehiculos', Element: Vehicles },
  { path: 'clientes', Element: Customers },
  { path: 'tarifas', Element: Prices },
  { path: 'ventaTicket', Element: SaleTicket },
  { path: 'nuevaRemesa', Element: Remittance },
  { path: 'pago-vehiculo', Element: Payment },
  { path: 'revision-vehiculo', Element: ReviewVehicle },
  { path: 'planillas', Element: Spreadsheets },
  { path: 'iniciar-ruta', Element: InitRoute },
  { path: 'imprimir-tiquete', Element: PrintTicketScreen },
  { path: 'imprimir-tiquete-id', Element: PrintTicketWithIdScreen },
  { path: 'imprimir-planilla', Element: PrintSpreadsheetScreen },
  { path: 'crear-planilla', Element: CreateSpreadsheet },
  { path: 'tiquetes', Element: Tickets },
  { path: 'lista-pagos', Element: PaymentsList },
  { path: 'imprimir-remesa', Element: PrintRemittanceScreen },
  { path: 'listado-remesas', Element: RemittancesList },
  { path: 'lista-revisiones', Element: ReviewsList },
  { path: 'company', Element: Company },
  { path: 'user-permissions', Element: UserPermissions }
];
