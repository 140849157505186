export const HEADERS = [
  { title: 'ID' },
  { title: 'PLACA' },
  { title: 'MARCA' },
  { title: 'MODELO' },
  { title: 'PASAJEROS' },
  { title: 'SOAT' },
  { title: 'TEC. MECANICA' },
  { title: 'TODO RIESGO' },
  { title: 'T. OPERACIÓN' },
  { title: 'ESTADO' },
  { title: 'RAZÓN ESTADO' }
];
