import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CustomButton from '../../components/CustomButton';
import CustomTextInput from '../../components/CustomTextInput';
import { actionCreators as spreadsheetsAction } from '../../../config/redux/spreadsheets/actions';
import SelectCitiesPrice from '../../components/SelectCitiesPrice';
import useLoading from '../../../hooks/useLoading';
import { getFormattedCurrentDate } from '../../../utils/dateUtils';
import { getDriverData } from '../../../config/services/drivers';
import { getTicketData } from '../../../config/services/tickets';
import SelectVehicle from '../../components/SelectVehicle';
import CurrencyInput from '../../components/CurrencyInput';

import './CreateSpreadsheet.css';
import TicketsTable from './components/TicketsTable';
import ActivedTicketsModal from './components/ActivedTicketsModal';

function CreateSpreadsheet() {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
    control
  } = useForm();

  const commonProps = { errors, register };
  const dispatch = useDispatch();

  const [spreadSheetTickets, setSpreadSheetTickets] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);

  const lastSpreadsheetLoading = useSelector(
    ({ spreadsheets }) => spreadsheets.lastSpreadsheetLoading
  );
  const prices = useSelector(({ prices }) => prices.prices);

  useEffect(() => {
    setValue('fecha', getFormattedCurrentDate());
  }, [setValue]);

  const searchDriver = async () => {
    const idDriver = getValues('conductor');
    if (idDriver !== '') {
      setLoadingScreen(true);
      const driverData = await getDriverData(idDriver);
      if (driverData.data) {
        const fullName = `${driverData.data.nombre} ${driverData.data.apellido}`;
        const isActive = driverData.data.estado !== 'INACTIVO';
        if (!isActive) alert('Conductor inactivo');
        setValue(
          'conductor',
          driverData.data.id && isActive ? driverData.data.id : ''
        );
        setValue('nombre', fullName && isActive ? fullName : '');
        setValue(
          'telefono',
          driverData.data.telefono && isActive ? driverData.data.telefono : ''
        );
      }
      setLoadingScreen(false);
    } else {
      setValue('conductor', '');
      setValue('nombre', '');
      setValue('telefono', '');
    }
  };

  useLoading(lastSpreadsheetLoading || loadingScreen);
  const onSuccess = () => {
    if (getValues().estado === 'INACTIVO') {
      alert('Vehículo inactivo');
      setValue('vehiculo', '');
      setValue('placa', '');
      setValue('marca', '');
      setValue('estado', '');
    }
  };
  const handleAddTicket = async () => {
    const ticketId = getValues('ticket');
    if (ticketId !== '') {
      setLoadingScreen(true);
      const existTicket = spreadSheetTickets.filter(
        ticket => ticket.id.toString() === ticketId
      );
      if (existTicket.length === 0) {
        const ticketData = await getTicketData(ticketId);
        if (ticketData.ok) {
          switch (ticketData.data.estado) {
            case 'ACTIVO':
              const newArray = spreadSheetTickets;
              newArray.push(ticketData.data);
              setSpreadSheetTickets(newArray);
              break;
            case 'DESPACHADO':
              alert('Este tiquete ya fue asignado a otra planilla');
              break;
            case 'ANULADO':
              alert('Este tiquete esta anulado');
              break;
            default:
              break;
          }
        }
      }
      setValue('ticket', '');
      setLoadingScreen(false);
    }
  };

  const handleRemoveTicket = ticketId => {
    const newArray = spreadSheetTickets.filter(
      ticket => ticket.id !== ticketId
    );
    setSpreadSheetTickets(newArray);
  };

  const navigate = useNavigate();
  const onSubmit = data => {
    data.tickets = spreadSheetTickets;
    data.additional = data.additional
      ? data.additional.replace('$', '').replace('.', '')
      : '0';
    console.log(data.additional);
    dispatch(spreadsheetsAction.addSpreadsheet(data, navigate));
  };
  const handleCancel = () => {
    reset();
    setSpreadSheetTickets([]);
  };
  const handleCloseModal = () => setShowModal(false);
  const handleShowTickets = () => setShowModal(true);

  return (
    <React.Fragment>
      <ActivedTicketsModal
        show={showModal}
        handleClose={handleCloseModal}
        spreadsheetTickets={spreadSheetTickets}
        setSpreadSheetTickets={setSpreadSheetTickets}
      />
      <h2>Generar planilla</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <CustomTextInput
            name={'fecha'}
            type={'date'}
            {...commonProps}
            title={'FECHA'}
            readOnly
            column="col-md-3"
          />
          <SelectCitiesPrice
            name={'origen'}
            {...commonProps}
            prices={prices}
            column="col-md-3"
            required
          />
          <SelectCitiesPrice
            name={'destino'}
            {...commonProps}
            prices={prices}
            column="col-md-3"
            required
          />
          <div className={`text-input-container col-md-3`}>
            <label className={`label`}>{'ADICIONAL PLANILLA'}</label>

            <Controller
              control={control}
              name={'additional'}
              {...commonProps}
              render={({ field }) => (
                <CurrencyInput {...field} className={`form-control`} />
              )}
            />
            {errors['additional'] && (
              <span className="error">{'Este campo es obligatorio'}</span>
            )}
          </div>
        </div>
        <SelectVehicle
          commonProps={commonProps}
          setValue={setValue}
          getValues={getValues}
          onSuccess={onSuccess}
          required
        />
        <div className="row">
          <hr className="separator" />
          <h3>Conductor</h3>
          <CustomTextInput
            name={'conductor'}
            {...commonProps}
            title={'CÉDULA'}
            required
            column="col-md-3"
            onBlur={searchDriver}
          />
          <CustomTextInput
            name={'nombre'}
            {...commonProps}
            title={'NOMBRE'}
            readOnly
            column="col-md-3"
          />
          <CustomTextInput
            name={'telefono'}
            {...commonProps}
            title={'TELÉFONO'}
            readOnly
            column="col-md-3"
          />
        </div>

        <div className="row">
          <hr className="separator" />
          <h3>Pasajeros</h3>
          <CustomTextInput
            name={'ticket'}
            title={'NÚMERO TIQUETE'}
            column="col-md-3"
            {...commonProps}
          />
          <div className="col-md-3">
            <CustomButton
              type={'button'}
              buttonStyle={'btn--outline'}
              onClick={handleAddTicket}>
              Agregar
            </CustomButton>
            <CustomButton
              type={'button'}
              buttonStyle={'btn--outline'}
              onClick={handleShowTickets}>
              Ver Activos
            </CustomButton>
          </div>
        </div>
        {spreadSheetTickets && (
          <TicketsTable
            tickets={{ data: spreadSheetTickets }}
            removeTicket={handleRemoveTicket}
          />
        )}
        <div>
          <CustomButton>Generar planilla</CustomButton>
          <CustomButton
            type={'button'}
            buttonStyle={'btn--outline'}
            onClick={handleCancel}>
            Cancelar
          </CustomButton>
        </div>
      </form>
    </React.Fragment>
  );
}

export default CreateSpreadsheet;
