import { api } from '../api';

const spreadsheetsPath = '/spreadsheets';

export const getSpreadsheets = data =>
  api.post(`${spreadsheetsPath}/filter?page=${data.page}`, data);

export const saveSpreadsheet = data => api.post(spreadsheetsPath, data);

export const cancelSpreadsheet = id => api.delete(`${spreadsheetsPath}/${id}`);
