import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import { actionCreators as reviewsActions } from '../../../config/redux/reviews/actions';
import EmptyTable from '../../components/EmptyTable';
import CustomTextInput from '../../components/CustomTextInput';
import CustomButton from '../../components/CustomButton';
import { handleExportToXlsx } from '../../../utils/xlsxUtils';
import useLoading from '../../../hooks/useLoading';

import ReviewsTable from './components/ReviewsTable';

function ReviewsList() {
  const tableId = 'reviewsId';
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  useEffect(() => {
    dispatch(reviewsActions.getReviews({ page }));
  }, [dispatch, page]);
  const { reviews, reviewsLoading } = useSelector(state => state.reviews);

  useLoading(reviewsLoading);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm();
  const onSubmit = data =>
    dispatch(reviewsActions.getReviews({ page: 1, ...data }));
  const commonProps = { errors, register };
  const handleSeeAll = () => {
    dispatch(reviewsActions.getReviews({ page: 1 }));
    reset();
  };
  const handleExport = () => {
    handleExportToXlsx(tableId, 'Reviews');
  };
  return (
    <React.Fragment>
      <h2>Revisiones</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <CustomTextInput
            name={'id'}
            title={'REVISIÓN'}
            {...commonProps}
            column="col-md-2"
          />
          <CustomTextInput
            name={'vehiculo'}
            title={'VEHÍCULO'}
            {...commonProps}
            column="col-md-2"
          />
          <CustomTextInput
            name={'usuario'}
            title={'USUARIO'}
            {...commonProps}
            column="col-md-2"
          />
          <CustomTextInput
            name={'estado'}
            title={'ESTADO'}
            {...commonProps}
            column="col-md-2"
          />
        </div>
        <div className="row">
          <CustomTextInput
            name={'created_ini'}
            title={'F. venta ini.'}
            {...commonProps}
            column="col-md-2"
            type={'date'}
          />
          <CustomTextInput
            name={'created_fin'}
            title={'F. venta fin.'}
            {...commonProps}
            column="col-md-2"
            type={'date'}
          />
        </div>
        <div>
          <CustomButton type={'submit'}>Filtrar</CustomButton>
          <CustomButton
            type={'button'}
            buttonStyle={'btn--outline'}
            onClick={handleSeeAll}>
            Ver todos
          </CustomButton>
          <CustomButton
            type={'button'}
            buttonStyle={'btn--outline'}
            onClick={handleExport}>
            Exportar
          </CustomButton>
        </div>
      </form>
      {reviews?.data?.length > 0 ? (
        <ReviewsTable
          tableId={tableId}
          reviews={reviews}
          page={page}
          setPage={setPage}
        />
      ) : (
        <EmptyTable />
      )}
    </React.Fragment>
  );
}

export default ReviewsList;
