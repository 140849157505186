import { completeTypes, createTypes, withPostSuccess } from 'redux-recompose';

import {
  deleteTicket,
  getTickets,
  saveTicket,
  updateTicket
} from '../../services/tickets';

export const actions = createTypes(
  completeTypes({
    primaryActions: ['GET_TICKETS'],
    ignoredActions: [
      'ADD_TICKET',
      'DELETE_TICKET',
      'UPDATE_TICKET',
      'SET_LAST_TICKET'
    ]
  }),
  '@@TICKETS'
);

const TARGETS = {
  TICKETS: 'tickets',
  LAST_TICKET: 'lastTicket'
};

export const actionCreators = {
  getTickets: data => ({
    type: actions.GET_TICKETS,
    target: TARGETS.TICKETS,
    payload: data,
    service: getTickets
  }),
  addTicket: (data, navigate) => ({
    type: actions.ADD_TICKET,
    target: TARGETS.LAST_TICKET,
    payload: data,
    service: saveTicket,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionCreators.setLastTicket(response?.data));
        navigate(`/imprimir-tiquete`);
      })
    ]
  }),
  deleteTicket: id => ({
    type: actions.DELETE_TICKET,
    payload: id,
    service: deleteTicket,
    injections: [
      withPostSuccess((dispatch, _response) => {
        dispatch(actionCreators.getTickets({ page: 1 }));
      })
    ]
  }),
  updateTicket: data => ({
    type: actions.UPDATE_TICKET,
    payload: data,
    service: updateTicket,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionCreators.getTickets({ page: 1 }));
      })
    ]
  }),
  setLastTicket: data => ({
    type: actions.SET_LAST_TICKET,
    target: TARGETS.LAST_TICKET,
    payload: data
  })
};
