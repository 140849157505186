import { completeReducer, completeState, createReducer } from 'redux-recompose';
import Immutable from 'seamless-immutable';

import { actions } from './actions';

const stateDescription = {
  description: {
    vehicles: []
  }
};

export const initialState = completeState(stateDescription);

const reducerDescription = {
  primaryActions: [actions.GET_VEHICLES, actions.GET_VEHICLE],
  override: {}
};

export default createReducer(
  Immutable(initialState),
  completeReducer(reducerDescription)
);
