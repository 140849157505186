import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { actionCreators as driversActions } from '../../../../../config/redux/drivers/actions';
import CustomTextInput from '../../../../components/CustomTextInput';
import CustomButton from '../../../../components/CustomButton';
import SelectControled from '../../../../components/SelectControled';

import { DRIVER_STATUS } from './constants';

function FilterDriversForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  const dispatch = useDispatch();
  const commonProps = { errors, register };
  const onSubmit = data => dispatch(driversActions.getDrivers(data));
  const handleSeeAll = () => {
    dispatch(driversActions.getDrivers({ page: 1 }));
    reset();
  };

  const renderOptions = DRIVER_STATUS.map(option => (
    <option value={option.value} key={option.value}>{`${option.text}`}</option>
  ));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <CustomTextInput
          name={'id'}
          title={'CÉDULA'}
          {...commonProps}
          column="col-md-2"
        />
        <CustomTextInput
          name={'nombre'}
          title={'NOMBRE'}
          {...commonProps}
          column="col-md-2"
        />
        <CustomTextInput
          name={'apellido'}
          title={'APELLIDO'}
          {...commonProps}
          column="col-md-2"
        />
        <SelectControled
          name={'estado'}
          title={'ESTADO'}
          {...commonProps}
          column="col-md-2"
          options={renderOptions}
        />
      </div>
      <div>
        <CustomButton type={'submit'}>Filtrar</CustomButton>
        <CustomButton
          type={'button'}
          buttonStyle={'btn--outline'}
          onClick={handleSeeAll}>
          Ver todos
        </CustomButton>
      </div>
    </form>
  );
}

export default FilterDriversForm;
