import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actionCreators as customersActions } from '../../../config/redux/customers/actions';
import CustomerForm from '../../components/CustomerForm';
import CommonScreen from '../../components/CommonScreen';
import WarningModal from '../../components/WarningModal';

import './Customers.css';
import FilterCustomersForm from './components/FilterCustomersForm';

function Customers() {
  const headers = [
    { title: 'CÉDULA' },
    { title: 'NOMBRE' },
    { title: 'TELEFONO' }
  ];
  const [show, setShow] = useState(false);
  const [idCustomer, setIdCustomer] = useState(0);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { customers, customersLoading } = useSelector(state => state.customers);

  const onRemove = id => {
    setIdCustomer(id);
    setShow(true);
  };

  const handlePressOk = () => {
    dispatch(customersActions.deleteCustomer(idCustomer));
    setShow(false);
  };
  const handleCloseModal = () => setShow(false);

  useEffect(() => {
    dispatch(customersActions.getCustomers({ page }));
  }, [dispatch, page]);
  const modalMessage = `¿Esta seguro de eliminar el cliente con cédula ${idCustomer}?`;
  return (
    <React.Fragment>
      <WarningModal
        message={modalMessage}
        handleCloseModal={handleCloseModal}
        onPressOk={handlePressOk}
        show={show}
      />
      <CommonScreen
        title={'Clientes'}
        buttonTitle={'Nuevo Cliente'}
        ComponentForm={CustomerForm}
        headers={headers}
        data={customers}
        onRemove={onRemove}
        loading={customersLoading}
        Filter={FilterCustomersForm}
        page={page}
        setPage={setPage}
      />
    </React.Fragment>
  );
}

export default Customers;
