import { completeTypes, createTypes, withPostSuccess } from 'redux-recompose';

import {
  deletePrice,
  getPrices,
  savePrice,
  updatePrice
} from '../../services/prices';

export const actions = createTypes(
  completeTypes({
    primaryActions: ['GET_PRICES'],
    ignoredActions: ['ADD_PRICE', 'DELETE_PRICE', 'UPDATE_PRICE']
  }),
  '@@PRICES'
);

const TARGETS = {
  PRICES: 'prices'
};

export const actionCreators = {
  getPrices: () => ({
    type: actions.GET_PRICES,
    target: TARGETS.PRICES,
    service: getPrices
  }),
  addPrice: data => ({
    type: actions.ADD_PRICE,
    payload: data,
    service: savePrice,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionCreators.getPrices());
      })
    ]
  }),
  deletePrice: id => ({
    type: actions.DELETE_PRICE,
    payload: id,
    service: deletePrice,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionCreators.getPrices());
      })
    ]
  }),
  updatePrice: data => ({
    type: actions.UPDATE_PRICE,
    payload: data,
    service: updatePrice,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionCreators.getPrices());
      })
    ]
  })
};
