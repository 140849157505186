import React from 'react';

import ReviewRow from '../ReviewRow';
import CommonTable from '../../../../components/CommonTable';
import TablePagination from '../../../../components/TablePagination';

function ReviewsTable({
  reviews: { data, current_page, last_page },
  tableId,
  page,
  setPage
}) {
  const headers = [
    { title: 'NÚMERO' },
    { title: 'VEHICULO' },
    { title: 'PLACA' },
    { title: 'USUARIO' },
    { title: 'FECHA' },
    { title: 'ESTADO' },
    { title: 'DESCRIPCIÓN' }
  ];
  const columnsHeader = headers.map((item, index) => (
    <th align="center" key={index}>
      {item.title}
    </th>
  ));
  const rowsBody = data.map(item => <ReviewRow {...item} />);
  return (
    <>
      <CommonTable
        tableId={tableId}
        columnsHeader={columnsHeader}
        rowsBody={rowsBody}
      />
      {current_page && (
        <TablePagination
          currentPage={current_page}
          lastPage={last_page}
          setPage={setPage}
          page={page}
        />
      )}
    </>
  );
}

export default ReviewsTable;
