import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { currencyFormatter } from '../../../utils/currencyUtils';
import PrintHeader from '../PrintHeader';

import './PrintSpreadsheet.css';

export const PrintSpreadsheet = React.forwardRef(
  (
    {
      id,
      created_at,
      origen,
      destino,
      usuario,
      vehiculo,
      driver,
      vehicle,
      tickets_spreadsheet,
      estado,
      additional
    },
    ref
  ) => {
    const companies = useSelector(state => state.companies.companies);
    const price = companies[0].price;
    const generateDate = new Date(created_at);
    const renderTickets = tickets_spreadsheet.map(ticket => {
      return (
        <tr key={ticket.id_ticket}>
          <td>
            <Link
              to={'/imprimir-tiquete-id'}
              state={{
                id: ticket.id_ticket,
                date: generateDate.toLocaleString()
              }}>
              {ticket.id_ticket}
            </Link>
          </td>
          <td>{ticket.ticket.customer.nombre}</td>
          <td>{ticket.ticket.destino}</td>
          <td align="right">{ticket.ticket.puestos}</td>
        </tr>
      );
    });

    return (
      <div ref={ref} className="spreadsheet-container">
        <PrintHeader />
        <span className="ticket-anulado">{estado === 'ANULADA' && estado}</span>
        <hr />
        <table>
          <tr className="tr-border">
            <td>Planilla No:</td>
            <td align="right">
              <strong>{id}</strong>
            </td>
          </tr>
        </table>
        Fecha y Hora:
        <p className="clear-space">
          <strong>{generateDate.toLocaleString()}</strong>
        </p>
        Vehículo - Placa:
        <p className="clear-space">
          <strong>{`${vehiculo} - ${vehicle.placa}`}</strong>
        </p>
        Conductor:
        <p className="clear-space">
          <strong>{`${driver.id} ${driver.nombre} ${driver.apellido}`}</strong>
        </p>
        Costo planilla:
        <p className="clear-space">
          <strong>{currencyFormatter(price)}</strong>
        </p>
        Adicional planilla:
        <p className="clear-space">
          <strong>{currencyFormatter(additional)}</strong>
        </p>
        Origen - Destino:
        <p className="clear-space">
          <strong>{`${origen} - ${destino}`}</strong>
        </p>
        <table>
          <thead>
            <tr>
              <th>Tiquete</th>
              <th>Cliente</th>
              <th>Destino</th>
              <th>Puestos</th>
            </tr>
          </thead>
          <tbody>{renderTickets}</tbody>
        </table>
        <div className="observaciones text-footer">
          <p className="clear-space">{`Usuario: ${usuario}`}</p>
          <p className="clear-space">{`Impreso por software transporte`}</p>
        </div>
      </div>
    );
  }
);
