import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';

import ActivedTicketsTable from '../ActivedTickestTable';
import { actionCreators as TicketsActions } from '../../../../../config/redux/tickets/actions';
import useLoading from '../../../../../hooks/useLoading';

function ActivedTicketsModal({
  show,
  handleClose,
  spreadsheetTickets,
  setSpreadSheetTickets
}) {
  const { tickets, ticketsLoading } = useSelector(state => state.tickets);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(TicketsActions.getTickets({ page: 1, estado: 'ACTIVO' }));
  }, [dispatch]);
  useLoading(ticketsLoading);
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Body>
        <h2>Tiquetes activos</h2>
        <ActivedTicketsTable
          tickets={{ data: tickets?.data || [] }}
          handleClose={handleClose}
          spreadsheetTickets={spreadsheetTickets}
          setSpreadSheetTickets={setSpreadSheetTickets}
        />
      </Modal.Body>
    </Modal>
  );
}

export default ActivedTicketsModal;
