import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { handleExportToXlsx } from '../../../utils/xlsxUtils';
import { actionCreators as remittancesActions } from '../../../config/redux/remittances/actions';
import CustomTextInput from '../../components/CustomTextInput';
import CustomButton from '../../components/CustomButton';
import SelectCitiesPrice from '../../components/SelectCitiesPrice';
import EmptyTable from '../../components/EmptyTable';
import useLoading from '../../../hooks/useLoading';
import { usePermissions } from '../../../hooks/usePermissions';

import RemittanceTable from './components/RemittanceTable';

function RemittancesList() {
  const { remittances, remittancesLoading } = useSelector(
    state => state.remittances
  );
  const hasPermissionEdit = usePermissions();
  const [page, setPage] = useState(1);
  const { prices } = useSelector(state => state.prices);
  const navigate = useNavigate();

  const remittancesTotal = remittances?.data
    ? remittances.data
        ?.map(item => item.valor)
        .reduce((prev, pre) => Number(prev) + Number(pre), 0)
    : 0;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(remittancesActions.getRemittances({ page }));
  }, [dispatch, page]);

  useLoading(remittancesLoading);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  const commonProps = { errors, register };

  const handleSeeAll = () => {
    dispatch(remittancesActions.getRemittances({ page: 1 }));
    reset();
  };
  const onSubmit = data => {
    dispatch(remittancesActions.getRemittances({ page: 1, ...data }));
  };
  const tableId = 'remesasTable';
  const addRowsToExport = [
    { text: 'Total', origin: { origin: -1 } },
    { text: remittancesTotal, origin: { origin: -1 } }
  ];
  const handleExport = () => {
    handleExportToXlsx(tableId, 'remesas', addRowsToExport);
  };
  const handleNewRemittance = () => navigate('/nuevaRemesa');
  return (
    <React.Fragment>
      <h2>Remesas</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <CustomTextInput
            name={'id'}
            title={'REMESA'}
            {...commonProps}
            column="col-md-2"
          />
          <SelectCitiesPrice
            name={'origen'}
            {...commonProps}
            prices={prices}
            column="col-md-3"
          />
          <SelectCitiesPrice
            name={'destino'}
            {...commonProps}
            prices={prices}
            column="col-md-3"
          />
          <CustomTextInput
            name={'usuario'}
            title={'USUARIO'}
            {...commonProps}
            column="col-md-2"
          />
        </div>
        <div className="row">
          <CustomTextInput
            name={'cedula_destinatario'}
            title={'CED. DESTINATARIO'}
            {...commonProps}
            column="col-md-2"
          />
          <CustomTextInput
            name={'cedula_remitente'}
            title={'CED. REMITENTE'}
            {...commonProps}
            column="col-md-2"
          />
        </div>
        <div className="row">
          <CustomTextInput
            name={'created_ini'}
            title={'F. venta ini.'}
            {...commonProps}
            column="col-md-2"
            type={'date'}
          />
          <CustomTextInput
            name={'created_fin'}
            title={'F. venta fin.'}
            {...commonProps}
            column="col-md-2"
            type={'date'}
          />
        </div>
        <div>
          <CustomButton type={'submit'}>Filtrar</CustomButton>
          <CustomButton
            type={'button'}
            buttonStyle={'btn--outline'}
            onClick={handleSeeAll}>
            Ver todos
          </CustomButton>
          <CustomButton
            type={'button'}
            buttonStyle={'btn--outline'}
            onClick={handleExport}>
            Exportar
          </CustomButton>
          <CustomButton
            type={'button'}
            buttonStyle="btn--primary"
            onClick={handleNewRemittance}>
            Nueva
          </CustomButton>
        </div>
      </form>
      <div>
        <h3>{`Total: $ ${remittancesTotal.toLocaleString('es-ES')}`}</h3>
      </div>
      <div>
        {remittances?.data?.length > 0 ? (
          <RemittanceTable
            tableId={tableId}
            remittances={remittances}
            hasPermissionEdit={hasPermissionEdit}
            page={page}
            setPage={setPage}
          />
        ) : (
          <EmptyTable />
        )}
      </div>
    </React.Fragment>
  );
}

export default RemittancesList;
