import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faPrint } from '@fortawesome/free-solid-svg-icons';

import { actionCreators } from '../../../../../config/redux/spreadsheets/actions';
import { currencyFormatter } from '../../../../../utils/currencyUtils';

function RowItem({ item, setSpreadsheetId, setShow, hasPermissionEdit }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const saleDate = new Date(item.created_at);
  const handlePressPrint = () => {
    dispatch(actionCreators.setLastSpreadsheet(item));
    navigate(`/imprimir-planilla`);
  };
  const tickets = item.tickets_spreadsheet.reduce(
    (concat, element) => concat + element.id_ticket + ', ',
    ''
  );
  const handleSetNull = () => {
    setSpreadsheetId(item.id);
    setShow(true);
  };

  return (
    <tr key={item.id}>
      <td align="center">{item.id}</td>
      <td align="center">{item.vehiculo}</td>
      <td align="center">{item.conductor}</td>
      <td align="center">{item.origen}</td>
      <td align="center">{item.destino}</td>
      <td align="center">{item.estado}</td>
      <td align="center">{item?.usuario?.toUpperCase()}</td>
      <td align="center">{currencyFormatter(item.additional)}</td>
      <td align="center">{saleDate.toLocaleString()}</td>
      <td align="center">{tickets.slice(0, -2)}</td>
      {hasPermissionEdit && (
        <td>
          <FontAwesomeIcon
            icon={faBan}
            onClick={handleSetNull}
            className={`icon ${item.estado === 'ANULADA' && 'fa-disabled'}`}
          />
        </td>
      )}
      <td>
        <FontAwesomeIcon
          icon={faPrint}
          onClick={handlePressPrint}
          className={'icon'}
        />
      </td>
    </tr>
  );
}

export default memo(RowItem);
