import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { notHasPermissions } from '../utils/navigation';

export const usePermissions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [hasPermissionEdit, setHasPermissionEdit] = useState(true);
  const screenPermissions = useSelector(({ permissions }) =>
    permissions.permissions.find(
      permission => permission.menu_data.path === location.pathname
    )
  );

  useEffect(() => {
    if (screenPermissions) {
      setHasPermissionEdit(screenPermissions.permission === 'EDIT');
    } else {
      notHasPermissions(navigate);
    }
  }, [navigate, screenPermissions]);

  return hasPermissionEdit;
};
