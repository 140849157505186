import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import './MenuCard.css';

function MenuCard({ icon, text, path }) {
  return (
    <Link to={path} className="card-container">
      <FontAwesomeIcon icon={icon} className={'icon-card'} />
      <h4 className="text-card">{text}</h4>
    </Link>
  );
}

export default memo(MenuCard);
