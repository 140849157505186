import React, { memo } from 'react';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from 'react-router-dom';

import NavLink from '../../NavLink';

function ItemMenu({ key, item }) {
  const navigate = useNavigate();
  const handleSelect = eventKey => navigate(eventKey);
  return (
    <Nav.Link key={key}>
      {item.elements ? (
        <NavDropdown
          title={item.title}
          id="basic-nav-dropdown"
          className="nav-links"
          menuVariant="dark"
          onSelect={handleSelect}>
          {item.elements.map((item, index) => (
            <NavDropdown.Item
              className="nav-item"
              eventKey={item.path}
              key={index}>
              {item.title}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      ) : (
        <NavLink {...item} />
      )}
    </Nav.Link>
  );
}

export default memo(ItemMenu);
