import React from 'react';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

import EmptyTable from '../../../../components/EmptyTable';

function TicketsTable({ tickets: { data }, removeTicket }) {
  const HEADERS = [
    { title: 'TIQUETE' },
    { title: 'CÉDULA' },
    { title: 'NOMBRE' },
    { title: 'TELÉFONO' },
    { title: 'PUESTOS' },
    { title: 'VALOR' }
  ];
  const columnsHeader = HEADERS.map((item, index) => (
    <th key={index}>{item.title}</th>
  ));
  const rowsBody = data.map(item => {
    return (
      <tr key={item.id}>
        <td>{item.id}</td>
        <td>{item.cliente}</td>
        <td>{item.customer.nombre}</td>
        <td>{item.customer.telefono}</td>
        <td>{item.puestos}</td>
        <td>{item.precio}</td>
        <td>
          <FontAwesomeIcon
            icon={faTrashCan}
            onClick={() => removeTicket(item.id)}
            className={'icon'}
          />
        </td>
      </tr>
    );
  });
  return (
    <div>
      {data.length > 0 ? (
        <Table className="custom-table">
          <thead>
            <tr>{columnsHeader}</tr>
          </thead>
          <tbody>{rowsBody}</tbody>
        </Table>
      ) : (
        <EmptyTable />
      )}
    </div>
  );
}

export default TicketsTable;
