import React, { useState } from 'react';

import useLoading from '../../hooks/useLoading';
import { usePermissions } from '../../hooks/usePermissions';

import CustomTable from './CustomTable';
import CustomButton from './CustomButton';
import EmptyTable from './EmptyTable';

function CommonScreen({
  ComponentForm,
  title,
  buttonTitle,
  headers,
  data,
  onRemove = () => null,
  loading = false,
  setPage,
  page,
  Filter,
  changePermissions
}) {
  const showedData = data.data ?? data;
  const [showForm, setShowForm] = useState(false);
  const [item, setItem] = useState({});
  const handleClose = () => setShowForm(false);
  const handleShow = () => setShowForm(true);
  const handleEdit = itemData => {
    setItem(itemData);
    setShowForm(true);
  };
  const hasPermissionEdit = usePermissions();
  useLoading(loading);

  return (
    <React.Fragment>
      <h1>{title}</h1>
      <div>
        {hasPermissionEdit && (
          <CustomButton onClick={handleShow}>{buttonTitle}</CustomButton>
        )}
        <ComponentForm show={showForm} handleClose={handleClose} item={item} />
      </div>
      {Filter && (
        <div>
          <Filter />
        </div>
      )}
      <div>
        {!loading &&
          (showedData.length > 0 ? (
            <CustomTable
              headers={headers}
              rows={showedData}
              onRemove={hasPermissionEdit && onRemove}
              onEdit={hasPermissionEdit && handleEdit}
              allData={data}
              setPage={setPage}
              page={page}
              changePermissions={hasPermissionEdit && changePermissions}
            />
          ) : (
            <EmptyTable />
          ))}
      </div>
    </React.Fragment>
  );
}

export default CommonScreen;
