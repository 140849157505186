import { api } from '../api';

const pricesPath = `/prices`;

export const getPrices = () => api.get(`${pricesPath}`);

export const savePrice = data => api.post(`${pricesPath}`, data);

export const deletePrice = id => api.delete(`${pricesPath}/${id}`);

export const updatePrice = data => api.put(`${pricesPath}/${data.id}`, data);
