import { completeTypes, createTypes } from 'redux-recompose';

export const actions = createTypes(
  completeTypes({
    primaryActions: [],
    ignoredActions: ['SET_APP_LOADING']
  }),
  '@@APP'
);

const TARGETS = {
  APP_LOADING: 'appLoading'
};

export const actionCreators = {
  setAppLoading: loading => ({
    type: actions.SET_APP_LOADING,
    target: TARGETS.APP_LOADING,
    payload: loading
  })
};
