import React from 'react';
import { useForm } from 'react-hook-form';

import CustomTextInput from '../../components/CustomTextInput';

function InitRoute() {
  const {
    register,
    formState: { errors }
  } = useForm();
  const commonProps = { errors, register };
  return (
    <React.Fragment>
      <form>
        <div className="row">
          <CustomTextInput
            name={'placa'}
            readOnly
            title={'PLACA'}
            column="col-md-4"
            {...commonProps}
          />
          <CustomTextInput
            name={'documentoConductor'}
            title={'DOCUMENTO DEL CONDUCTOR'}
            column="col-md-8"
            required
            {...commonProps}
          />
        </div>
      </form>
    </React.Fragment>
  );
}

export default InitRoute;
