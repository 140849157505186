import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { actionCreators as vehiclesActions } from '../../../../../config/redux/vehicles/actions';
import CustomTextInput from '../../../../components/CustomTextInput';
import CustomButton from '../../../../components/CustomButton';

function FilterVehicleForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  const dispatch = useDispatch();
  const commonProps = { errors, register };
  const onSubmit = data => dispatch(vehiclesActions.getVehicles(data));
  const handleSeeAll = () => {
    dispatch(vehiclesActions.getVehicles({ page: 1 }));
    reset();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <CustomTextInput
          name={'id'}
          title={'VEHICULO'}
          {...commonProps}
          column="col-md-2"
        />
        <CustomTextInput
          name={'placa'}
          title={'PLACA'}
          {...commonProps}
          column="col-md-2"
        />
      </div>
      <div>
        <CustomButton type={'submit'}>Filtrar</CustomButton>
        <CustomButton
          type={'button'}
          buttonStyle={'btn--outline'}
          onClick={handleSeeAll}>
          Ver todos
        </CustomButton>
      </div>
    </form>
  );
}

export default FilterVehicleForm;
