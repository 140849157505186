import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';

import { actionCreators as userActions } from '../../../config/redux/user/actions';
import { actionCreators as permissionsActions } from '../../../config/redux/permissions/actions';

import './Navbar.css';
import ItemMenu from './components/ItemMenu';

function NavbarMenu() {
  const dispatch = useDispatch();
  const permissions = useSelector(({ permissions }) => permissions.permissions);
  const currentUser = useSelector(({ user }) => user.currentUser);

  const handleLogout = event => {
    event.preventDefault();
    dispatch(userActions.logout());
  };

  useEffect(() => {
    if (currentUser) {
      dispatch(permissionsActions.getPermissions(currentUser.id));
    }
  }, [dispatch, currentUser]);

  const MENU_ITEMS = [
    {
      path: '/',
      title: 'INICIO',
      onClick: null
    },
    {
      title: 'VARIOS',
      onClick: null,
      elements: permissions?.map(permission => ({
        ...permission.menu_data
      }))
    },
    /* {
      path: '/soporte',
      title: 'SOPORTE',
      onClick: null
    }, */
    {
      path: '/logout',
      title: 'SALIR',
      onClick: handleLogout
    }
  ];

  const menuItems = MENU_ITEMS.map((item, index) => (
    <ItemMenu key={index} item={item} />
  ));

  return (
    <Navbar expand="lg" className="navbar">
      <Container>
        <Navbar.Brand href="#home">
          <Link to="/" className="navbar-logo">
            <i className="fas fa-taxi" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">{menuItems}</Nav>
          <Nav className="text-nav-bar" key={'/usuario'}>
            {currentUser?.nombre?.toUpperCase()}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarMenu;
