export const travelRoutes = [
  {
    origen: 'ARAUCA',
    destino: 'ARAUQUITA',
    ruta: 'Arauca - Todos los Santos - La Pesquera - Arauquita'
  },
  {
    origen: 'ARAUQUITA',
    destino: 'ARAUCA',
    ruta: 'Arauquita - La Pesquera - Todos los Santos - Arauca'
  },
  {
    origen: 'ARAUCA',
    destino: 'SARAVENA',
    ruta: 'Aruca - Todos los Santos - La Pesquera - Arauquita - La Esmeralda - Saravena'
  },
  {
    origen: 'SARAVENA',
    destino: 'ARAUCA',
    ruta: 'Saravena - La Esmeralda - Arauquita - La Pesquera - Todos los Santos - Arauca'
  },
  {
    origen: 'ARAUCA',
    destino: 'RONDóN',
    ruta: 'Arauca - Via puente Lipa - Panama de Arauca - Puerto Jordan - Puerto Rondón'
  },
  {
    origen: 'PUERTO RONDON',
    destino: 'ARAUCA',
    ruta: 'Puerto Rondón - Puerto Jordan - Panama de Arauca - Via puente Lipa - Arauca'
  },
  {
    origen: 'ARAUCA',
    destino: 'PUERTO JORDAN',
    ruta: 'Arauca - Via puente Lipa - Panama de Arauca - Puerto Jordan'
  },
  {
    origen: 'PUERTO JORDAN',
    destino: 'ARAUCA',
    ruta: 'Puerto Jordan - Panama de Arauca- Via puente Lipa - Arauca'
  },
  {
    origen: 'ARAUQUITA',
    destino: 'SARAVENA',
    ruta: 'Arauquita - La Esmeralda - Saravena'
  },
  {
    origen: 'SARAVENA',
    destino: 'ARAUQUITA',
    ruta: 'Saravena - La Esmeralda - Arauquita'
  }
];
