import {
  completeTypes,
  createTypes,
  withPostFailure,
  withPostSuccess
} from 'redux-recompose';

import {
  deleteVehicle,
  getVehicleData,
  getVehicles,
  saveVehicle,
  updateVehicle
} from '../../services/vehicles';

export const actions = createTypes(
  completeTypes({
    primaryActions: ['GET_VEHICLES'],
    ignoredActions: [
      'ADD_VEHICLE',
      'DELETE_VEHICLE',
      'UPDATE_VEHICLE',
      'GET_VEHICLE'
    ]
  }),
  '@@VEHICLES'
);

const TARGETS = {
  VEHICLES: 'vehicles'
};

export const actionCreators = {
  getVehicles: payload => ({
    type: actions.GET_VEHICLES,
    target: TARGETS.VEHICLES,
    payload,
    service: getVehicles
  }),
  addVehicle: data => ({
    type: actions.ADD_VEHICLE,
    payload: data,
    service: saveVehicle,
    injections: [
      withPostSuccess((dispatch, _response) => {
        dispatch(actionCreators.getVehicles({ page: 1 }));
      }),
      withPostFailure((_dispatch, _response) => {
        alert('No se pudo agregar el vehículo');
      })
    ]
  }),
  deleteVehicle: id => ({
    type: actions.DELETE_VEHICLE,
    payload: id,
    service: deleteVehicle,
    injections: [
      withPostSuccess((dispatch, _response) => {
        dispatch(actionCreators.getVehicles({ page: 1 }));
      }),
      withPostFailure((_dispatch, _response) => {
        alert('No se pudo eliminar el vehículo');
      })
    ]
  }),
  updateVehicle: data => ({
    type: actions.UPDATE_VEHICLE,
    payload: data,
    service: updateVehicle,
    injections: [
      withPostSuccess((dispatch, _response) => {
        dispatch(actionCreators.getVehicles({ page: 1 }));
      }),
      withPostFailure((_dispatch, _response) => {
        alert('No se pudo actualizar el vehículo');
      })
    ]
  }),
  getVehicle: id => ({
    type: actions.GET_VEHICLE,
    target: TARGETS.SELECTED_VEHICLE,
    payload: id,
    service: getVehicleData
  })
};
