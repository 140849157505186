import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useLocation, useNavigate } from 'react-router-dom';

import { PrintTicket } from '../../components/PrintTicket';
import CustomButton from '../../components/CustomButton';
import { getTicketData } from '../../../config/services/tickets';
import useLoading from '../../../hooks/useLoading';

function PrintTicketWithIdScreen() {
  const [ticket, setTicket] = useState();
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const componentRef = useRef();
  const navigate = useNavigate();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });
  const handleNewTicket = () => navigate('/ventaTicket');
  const getTicket = useCallback(async () => {
    setLoading(true);
    const response = await getTicketData(state.id);
    setTicket(response.data);
    setLoading(false);
  }, [state.id]);

  useEffect(() => {
    getTicket();
  }, [getTicket]);

  useLoading(loading);

  return (
    <React.Fragment>
      {ticket && (
        <PrintTicket ref={componentRef} {...ticket} fecha={state.date} />
      )}
      <CustomButton
        type={'button'}
        buttonStyle="btn--primary"
        onClick={handlePrint}>
        Imprimir
      </CustomButton>
      <CustomButton
        type={'button'}
        buttonStyle="btn--primary"
        onClick={handleNewTicket}>
        Nuevo
      </CustomButton>
    </React.Fragment>
  );
}

export default PrintTicketWithIdScreen;
