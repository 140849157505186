import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import { actionCreators as paymentsActions } from '../../../config/redux/payments/actions';
import EmptyTable from '../../components/EmptyTable';
import CustomTextInput from '../../components/CustomTextInput';
import CustomButton from '../../components/CustomButton';
import SelectMonthControlled from '../../components/SelectMonthControlled';
import { handleExportToXlsx } from '../../../utils/xlsxUtils';
import useLoading from '../../../hooks/useLoading';
import SelectControled from '../../components/SelectControled';

import PaymentsTable from './components/PaymentsTable';

function PaymentsList() {
  const tableId = 'paymentsId';
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  useEffect(() => {
    dispatch(paymentsActions.getPayments({ page }));
  }, [dispatch, page]);
  const { payments, paymentsLoading } = useSelector(state => state.payments);

  useLoading(paymentsLoading);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm();
  const onSubmit = data =>
    dispatch(paymentsActions.getPayments({ page: 1, ...data }));
  const commonProps = { errors, register };
  const handleSeeAll = () => {
    dispatch(paymentsActions.getPayments({ page: 1 }));
    reset();
  };
  const handleExport = () => {
    handleExportToXlsx(tableId, 'Payments');
  };
  const years = [
    { text: '2022', value: 2022 },
    { text: '2023', value: 2023 },
    { text: '2024', value: 2024 },
    { text: '2025', value: 2025 },
    { text: '2026', value: 2026 },
    { text: '2027', value: 2027 },
    { text: '2028', value: 2028 },
    { text: '2029', value: 2029 },
    { text: '2030', value: 2030 }
  ];
  const renderOptions = years.map(option => (
    <option value={option.value} key={option.value}>{`${option.text}`}</option>
  ));
  return (
    <React.Fragment>
      <h2>Pagos</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <CustomTextInput
            name={'id'}
            title={'PAGO'}
            {...commonProps}
            column="col-md-2"
          />
          <CustomTextInput
            name={'vehicle_id'}
            title={'VEHÍCULO'}
            {...commonProps}
            column="col-md-2"
          />
          <CustomTextInput
            name={'user'}
            title={'USUARIO'}
            {...commonProps}
            column="col-md-2"
          />
          <CustomTextInput
            name={'status'}
            title={'ESTADO'}
            {...commonProps}
            column="col-md-2"
          />
          <SelectMonthControlled
            name={'month'}
            title={'MES'}
            {...commonProps}
            column="col-md-2"
          />
          <SelectControled
            name={'year'}
            {...commonProps}
            title={'AÑO'}
            options={renderOptions}
            column="col-md-6"
          />
        </div>
        <div className="row">
          <CustomTextInput
            name={'created_ini'}
            title={'F. venta ini.'}
            {...commonProps}
            column="col-md-2"
            type={'date'}
          />
          <CustomTextInput
            name={'created_fin'}
            title={'F. venta fin.'}
            {...commonProps}
            column="col-md-2"
            type={'date'}
          />
        </div>
        <div>
          <CustomButton type={'submit'}>Filtrar</CustomButton>
          <CustomButton
            type={'button'}
            buttonStyle={'btn--outline'}
            onClick={handleSeeAll}>
            Ver todos
          </CustomButton>
          <CustomButton
            type={'button'}
            buttonStyle={'btn--outline'}
            onClick={handleExport}>
            Exportar
          </CustomButton>
        </div>
      </form>
      {payments?.data?.length > 0 ? (
        <PaymentsTable
          tableId={tableId}
          payments={payments}
          page={page}
          setPage={setPage}
        />
      ) : (
        <EmptyTable />
      )}
    </React.Fragment>
  );
}

export default PaymentsList;
