import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actionCreators as driversActions } from '../../../config/redux/drivers/actions';
import DriverForm from '../../components/DriverForm';
import CommonScreen from '../../components/CommonScreen';
import WarningModal from '../../components/WarningModal';

import FilterDriversForm from './components/FilterDriversForm';

function Drivers() {
  const headers = [
    { title: 'ID' },
    { title: 'NOMBRE' },
    { title: 'APELLIDO' },
    { title: 'TELEFONO' },
    { title: 'V. LICENCIA' },
    { title: 'ESTADO' },
    { title: 'RAZÓN' }
  ];
  const [idDriver, setIdDriver] = useState(0);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { drivers, driversLoading } = useSelector(state => state.drivers);

  const onRemove = id => {
    setIdDriver(id);
    setShow(true);
  };

  useEffect(() => {
    dispatch(driversActions.getDrivers({ page: 1, estado: 'ACTIVO' }));
  }, [dispatch, page]);

  const handlePressOk = () => {
    dispatch(driversActions.deleteDriver(idDriver));
    setShow(false);
  };
  const handleCloseModal = () => setShow(false);
  const modalMessage = `¿Esta seguro de eliminar el conductor con cédula ${idDriver}?`;
  return (
    <React.Fragment>
      <WarningModal
        message={modalMessage}
        handleCloseModal={handleCloseModal}
        onPressOk={handlePressOk}
        show={show}
      />
      <CommonScreen
        title={'Conductores'}
        buttonTitle={'Nuevo Conductor'}
        ComponentForm={DriverForm}
        headers={headers}
        data={drivers}
        onRemove={onRemove}
        loading={driversLoading}
        setPage={setPage}
        page={page}
        Filter={FilterDriversForm}
      />
    </React.Fragment>
  );
}

export default Drivers;
