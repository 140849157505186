import React from 'react';

import LoginForm from '../components/LoginForm';
import logo from '../assets/logoCootransaraucana1080.jpg';
import './Login.css';

function Login() {
  return (
    <div className="contenedor">
      <div className="blur-background">
        <div className="contenedorcentrado">
          <div className="login">
            <div className="loginform">
              <LoginForm />
            </div>
          </div>
          <div className="derecho">
            <div className="titulo">Bienvenido</div>
            <img src={logo} alt="logo cootrasnaraucana" />
            <hr />
            <div className="pie-form">ZUVE</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
