import * as XLSX from 'xlsx';

export const handleExportToXlsx = (tableId, fileName, moreRows) => {
  const table_elt = document.getElementById(tableId);
  const workbook = XLSX.utils.table_to_book(table_elt);
  const ws = workbook.Sheets['Sheet1'];
  if (moreRows) {
    moreRows.map(row => XLSX.utils.sheet_add_aoa(ws, [[row.text]], row.origin));
  }
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};
