import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { actionCreators as ticketAction } from '../../../../../config/redux/tickets/actions';
import WarningModal from '../../../../components/WarningModal';
import { currencyFormatter } from '../../../../../utils/currencyUtils';

function PaymentRow({
  id,
  fecha,
  customer,
  origen,
  destino,
  precio,
  puestos,
  usuario,
  estado,
  created_at,
  hasPermissionEdit,
  ...props
}) {
  const createdDate = new Date(created_at);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handlePressDeleteTicket = () => setShow(true);
  const handleDelete = () => {
    dispatch(ticketAction.deleteTicket(id));
    setShow(false);
  };
  const modalMessage = `¿Seguro quiere anular el tiquete No. ${id}?`;
  const handleCloseModal = () => setShow(false);
  const navigate = useNavigate();
  const handlePressPrint = () => {
    dispatch(
      ticketAction.setLastTicket({
        id,
        fecha,
        customer,
        origen,
        destino,
        precio,
        puestos,
        usuario,
        estado,
        created_at,
        ...props
      })
    );
    navigate(`/imprimir-tiquete`);
  };
  return (
    <React.Fragment>
      <WarningModal
        message={modalMessage}
        handleCloseModal={handleCloseModal}
        onPressOk={handleDelete}
        show={show}
      />
      <tr key={id}>
        <td align="center">{id}</td>
        <td align="center">{fecha}</td>
        <td>{customer.nombre}</td>
        <td>{origen}</td>
        <td>{destino}</td>
        <td align="right">{currencyFormatter(precio)}</td>
        <td align="center">{puestos}</td>
        <td align="center">{usuario}</td>
        <td align="center">{estado}</td>
        <td align="center">{createdDate.toLocaleString()}</td>
        {hasPermissionEdit && (
          <td>
            <FontAwesomeIcon
              icon={faBan}
              onClick={handlePressDeleteTicket}
              className={`icon ${estado === 'ANULADO' && 'fa-disabled'}`}
            />
          </td>
        )}
        <td>
          <FontAwesomeIcon
            icon={faPrint}
            onClick={handlePressPrint}
            className={'icon'}
          />
        </td>
      </tr>
    </React.Fragment>
  );
}

export default PaymentRow;
