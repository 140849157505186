import {
  completeTypes,
  createTypes,
  withPostFailure,
  withPostSuccess
} from 'redux-recompose';

import { getReviews, saveReview } from '../../services/reviews';

export const actions = createTypes(
  completeTypes({
    primaryActions: ['GET_REVIEWS'],
    ignoredActions: ['ADD_REVIEW']
  }),
  '@@REVIEWS'
);

const TARGET = {
  REVIEWS: 'reviews'
};

export const actionCreators = {
  getReviews: payload => ({
    type: actions.GET_REVIEWS,
    target: TARGET.REVIEWS,
    service: getReviews,
    payload
  }),
  addReview: (payload, onSuccess, onFailure) => ({
    type: actions.ADD_REVIEW,
    target: TARGET.REVIEWS,
    payload,
    service: saveReview,
    injections: [
      withPostSuccess((_dispatch, _response) => {
        onSuccess();
      }),
      withPostFailure((_dispatch, _response) => {
        onFailure();
      })
    ]
  })
};
