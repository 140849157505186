import { api } from '../api';

const remittancesPath = 'remittances';

export const saveRemittance = data => api.post(remittancesPath, data);

export const getRemittances = data =>
  api.post(`${remittancesPath}/filter?page=${data.page}`, data);

export const deleteRemittance = id => api.delete(`${remittancesPath}/${id}`);
