import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { actionCreators } from '../config/redux/appReducer/actions';

function useLoading(loading) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actionCreators.setAppLoading(loading));
  }, [dispatch, loading]);
}

export default useLoading;
