import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';

import { actionCreators as driversActions } from '../../config/redux/drivers/actions';
import { DRIVER_STATUS } from '../screens/Drivers/components/FilterDriversForm/constants';

import CustomTextInput from './CustomTextInput';
import CustomButton from './CustomButton';
import './ModalForm.css';
import SelectControled from './SelectControled';

function DriverForm({ item = null, show, handleClose }) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset
  } = useForm();
  const commonProps = { errors, register };
  const onSubmit = data => {
    dispatch(
      item?.id
        ? driversActions.updateDriver(data)
        : driversActions.addDriver(data)
    );
    reset();
    handleClose();
  };

  useEffect(() => {
    if (item?.id) {
      Object.entries(item).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [item, setValue]);

  const renderOptions = DRIVER_STATUS.map(option => (
    <option value={option.value} key={option.value}>{`${option.text}`}</option>
  ));

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomTextInput
            name={'id'}
            title={'CÉDULA'}
            {...commonProps}
            readOnly={item?.id}
            required
          />
          <CustomTextInput
            name={'nombre'}
            required
            {...commonProps}
            title={'NOMBRE'}
          />
          <CustomTextInput
            name={'apellido'}
            required
            {...commonProps}
            title={'APELLIDO'}
          />
          <CustomTextInput
            name={'telefono'}
            {...commonProps}
            title={'TELEFONO'}
          />
          <CustomTextInput
            name={'licencia'}
            {...commonProps}
            required
            type={'date'}
            title={'FECHA VENCIMIENTO LICENCIA'}
          />
          <SelectControled
            name={'estado'}
            {...commonProps}
            required
            title={'ESTADO'}
            options={renderOptions}
          />
          <CustomTextInput
            name={'razon_estado'}
            {...commonProps}
            title={'RAZÓN'}
          />
          <div className="btn-group">
            <CustomButton type={'submit'}>Guardar</CustomButton>
            <CustomButton
              type={'button'}
              buttonStyle={'btn--outline'}
              onClick={handleClose}>
              Cancelar
            </CustomButton>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default DriverForm;
