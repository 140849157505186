import { create, NETWORK_ERROR } from 'apisauce';

const AUTHORIZATION_HEADER = 'Authorization';
const ACCESS_CONTROL_ALLOW_ORIGIN = 'Access-Control-Allow-Origin';
const BEARER = 'Bearer';

export const api = create({
  baseURL: process.env.REACT_APP_URL_BASE + '/api',
  timeout: 15000
});

export const setApiHeaders = token => {
  api.setHeader(AUTHORIZATION_HEADER, `${BEARER} ${token}`);
};

export const removeApiHeaders = () => {
  api.deleteHeader(AUTHORIZATION_HEADER);
};

export const apiSetup = () => {
  api.setHeader(ACCESS_CONTROL_ALLOW_ORIGIN, '*');
  api.addMonitor(response => {
    if (response.problem === NETWORK_ERROR) {
      console.error('connection failed: ' + NETWORK_ERROR);
    }
  });
  api.addMonitor(response => {
    if (response.status === 401) {
      console.warn(response.data.message);
    }
  });
};
