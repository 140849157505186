import React from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import Modal from 'react-bootstrap/Modal';

import './Loading.css';

function Loading({ show }) {
  return (
    <Modal
      show={show}
      centered
      size="sm"
      contentClassName="modal-content-loading"
      keyboard={false}
    >
      <Modal.Body className="modal-loading">
        <ThreeCircles
          color="red"
          outerCircleColor="green"
          innerCircleColor="green"
        />
      </Modal.Body>
    </Modal>
  );
}

export default Loading;
