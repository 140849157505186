import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

function TablePagination({ lastPage, currentPage, setPage }) {
  return (
    <Pagination>
      <Pagination.First
        onClick={() => setPage(1)}
        disabled={currentPage === 1}
      />
      <Pagination.Prev
        onClick={() => setPage(currentPage - 1)}
        disabled={currentPage === 1}
      />
      <Pagination.Item size="sm">{currentPage}</Pagination.Item>
      <Pagination.Next
        onClick={() => setPage(currentPage + 1)}
        disabled={currentPage === lastPage}
      />
      <Pagination.Last
        onClick={() => setPage(lastPage)}
        disabled={currentPage === lastPage}
      />
    </Pagination>
  );
}

export default TablePagination;
