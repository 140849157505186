import React from 'react';
import Table from 'react-bootstrap/Table';

import TicketRow from '../TicketRow';
import TablePagination from '../../../../components/TablePagination';

function TicketsTable({
  tickets: { data, current_page, last_page },
  tableId,
  hasPermissionEdit,
  page,
  setPage
}) {
  const headers = [
    { title: 'NÚMERO' },
    { title: 'F. VIAJE' },
    { title: 'CLIENTE' },
    { title: 'ORIGEN' },
    { title: 'DESTINO' },
    { title: 'PRECIO' },
    { title: 'PUESTOS' },
    { title: 'VENDEDOR' },
    { title: 'ESTADO' },
    { title: 'F. VENTA' }
  ];
  const columnsHeader = headers.map((item, index) => (
    <th align="center" key={index}>
      {item.title}
    </th>
  ));
  const rowsBody = data.map(item => (
    <TicketRow {...item} hasPermissionEdit={hasPermissionEdit} />
  ));
  return (
    <div>
      <Table className="custom-table" id={tableId}>
        <thead>
          <tr>{columnsHeader}</tr>
        </thead>
        <tbody>{rowsBody}</tbody>
      </Table>
      {current_page && (
        <TablePagination
          currentPage={current_page}
          lastPage={last_page}
          setPage={setPage}
          page={page}
        />
      )}
    </div>
  );
}

export default TicketsTable;
