import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { actionCreators as userActions } from '../../config/redux/user/actions';
import showPassword from '../assets/showPassword.png';
import hidePassword from '../assets/hidePassword.png';

import CustomButton from './CustomButton';
import './LoginForm.css';

function LoginForm() {
  const dispatch = useDispatch();
  const [showPass, setShowPass] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const onSubmit = data => {
    dispatch(userActions.login(data));
  };
  const handleShowClick = () => setShowPass(!showPass);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label>Usuario</label>
      <input
        {...register('name', { required: true })}
        className="form-control"
      />
      {errors.user && <span>Este campo es obligatorio</span>}
      <br />
      <label>Contraseña</label>
      <div className="password-container">
        <input
          type={showPass ? 'text' : 'password'}
          {...register('password', { required: true })}
          className="form-control"
        />
        <img
          className="icon-show"
          src={showPass ? hidePassword : showPassword}
          alt="show password"
          onClick={handleShowClick}
        />
      </div>
      {errors.password && <span>Este campo es obligatorio</span>}
      <br />
      <div className="button-container">
        <CustomButton type={'submit'} buttonStyle={'btn-primary'}>
          Iniciar Sesión
        </CustomButton>
      </div>
    </form>
  );
}

export default LoginForm;
