import { api } from '../api';

const vehiclesPath = `/vehicles`;

export const getVehicles = ({ page = 1, ...data }) =>
  api.post(`${vehiclesPath}/filter?page=${page}`, data);

export const saveVehicle = data => api.post(`${vehiclesPath}`, data);

export const deleteVehicle = id => api.delete(`${vehiclesPath}/${id}`);

export const updateVehicle = data =>
  api.put(`${vehiclesPath}/${data.id}`, data);

export const getVehicleData = id => api.get(`${vehiclesPath}/${id}`);
