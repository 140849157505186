import {
  completeTypes,
  createTypes,
  withPostFailure,
  withPostSuccess
} from 'redux-recompose';

import { getCompanies, updateCompanies } from '../../services/companies';

export const actions = createTypes(
  completeTypes({
    primaryActions: ['GET_COMPANIES'],
    ignoredActions: ['UPDATE_COMPANIES']
  }),
  '@@COMPANIES'
);

const TARGETS = {
  COMPANIES: 'companies'
};

export const actionCreators = {
  getCompanies: payload => ({
    type: actions.GET_COMPANIES,
    target: TARGETS.COMPANIES,
    payload,
    service: getCompanies
  }),
  updateCompanies: data => ({
    type: actions.UPDATE_COMPANIES,
    payload: data,
    service: updateCompanies,
    injections: [
      withPostSuccess(() => {
        alert('Datos actualizados correctamente');
      }),
      withPostFailure((_dispatch, _response) => {
        alert('No se pudo actualizar los datos de la compañia');
      })
    ]
  })
};
