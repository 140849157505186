import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { USER_ROUTES } from '../config/constants/routes';
import { apiSetup } from '../config/api';
import useLoading from '../hooks/useLoading';
// import { actionCreators as userActions } from '../config/redux/user/actions';

import Loading from './components/Loading';
import App from './screens/App';
import Home from './screens/Home';
import Login from './screens/Login';
import Signup from './screens/Signup';

import './App.css';

function AppRoutes() {
  const { currentUser, currentUserLoading } = useSelector(state => state.user);
  const { appLoading } = useSelector(state => state.app);
  const renderRoutes = routes =>
    routes.map(({ path, Element }, index) => (
      <Route path={path} element={<Element />} key={index} />
    ));
  // const dispatch = useDispatch();
  useEffect(() => {
    apiSetup();
    // dispatch(userActions.init());
  }, []);
  useLoading(currentUserLoading);
  return (
    <BrowserRouter>
      <Loading show={appLoading} />
      {currentUser ? (
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Home />} />
            {renderRoutes(USER_ROUTES)}
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default AppRoutes;
