import React from 'react';

import MenuCard from '../../components/MenuCard';

import { ITEMS_MENU } from './constants';

import './Home.css';
function Home() {
  const renderMenu = ITEMS_MENU.map((item, index) => (
    <MenuCard {...item} key={index} />
  ));

  return (
    <div className="container-menu">
      <div className="content-menu">{renderMenu}</div>
    </div>
  );
}

export default Home;
