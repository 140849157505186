import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';

import { actionCreators as userActions } from '../../../config/redux/user/actions';
import CustomTextInput from '../CustomTextInput';
import CustomButton from '../CustomButton';
import SelectControled from '../SelectControled';
import '../ModalForm.css';

const USER_TYPE = [
  { option: 'USUARIO', value: 'user' },
  { option: 'ADMINISTRADOR', value: 'admin' }
];

function UserForm({ item = null, show, handleClose }) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm();
  const handleCancel = () => {
    reset();
    handleClose();
  };
  const onSubmit = data => {
    dispatch(
      item.id ? userActions.updateUser(data) : userActions.addUser(data)
    );

    handleCancel();
  };

  useEffect(() => {
    if (item?.id) {
      Object.entries(item).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [item, setValue]);

  const commonProps = { errors, register };
  const renderOptions = USER_TYPE.map(type => (
    <option value={type.value} key={type.value}>
      {`${type.option}`}
    </option>
  ));

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)} id={'form-user'}>
          <CustomTextInput
            name={'id'}
            title={'CÉDULA'}
            {...commonProps}
            readOnly={item?.id}
            required
          />
          <CustomTextInput
            name={'nombre'}
            required
            {...commonProps}
            title={'NOMBRE COMPLETO'}
          />
          <CustomTextInput
            name={'name'}
            required
            {...commonProps}
            title={'USUARIO'}
          />
          <CustomTextInput
            name={'password'}
            {...commonProps}
            required
            title={'CONTRASEÑA'}
            minLength={8}
          />
          <SelectControled
            name={'permissions'}
            title={'PERFIL'}
            options={renderOptions}
            {...commonProps}
          />
          <div className="btn-group">
            <CustomButton type={'submit'}>Guardar</CustomButton>
            <CustomButton
              type={'button'}
              buttonStyle={'btn--outline'}
              onClick={handleCancel}>
              Cancelar
            </CustomButton>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default UserForm;
