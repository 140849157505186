import { api } from '../api';

const permissionsPath = `/permissions`;

export const getPermissions = userId => api.get(`${permissionsPath}/${userId}`);

export const addPermission = data => api.post(`${permissionsPath}`, data);

export const updatePermission = (id, data) =>
  api.put(`${permissionsPath}/${id}`, data);

export const deletePermision = id => api.delete(`${permissionsPath}/${id}`);

export const deletePermissionsByUser = id =>
  api.delete(`${permissionsPath}/delete/${id}`);
