import React from 'react';

import './EmptyTable.css';
function EmptyTable() {
  return (
    <div className="empty-container">
      <span className="title">No existen elementos para mostrar</span>
      <div className="empty-content">
        <span className="backgroundContainer"></span>
        <span className="circle-empty"></span>
        <span className="line-empty"></span>
      </div>
      {/* <img src={emptyTablePic} alt="tabla vacia" /> */}
    </div>
  );
}

export default EmptyTable;
