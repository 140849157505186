import { api } from '../api';

const customersPath = `/customers`;

export const getCustomers = data =>
  api.post(`${customersPath}/filter?page=${data.page}`, data);

export const saveCustomer = data => api.post(`${customersPath}`, data);

export const deleteCustomer = id => api.delete(`${customersPath}/${id}`);

export const updateCustomer = data =>
  api.put(`${customersPath}/${data.id}`, data);

export const getCustomerData = id => api.get(`${customersPath}/${id}`);
