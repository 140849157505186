import {
  completeTypes,
  createTypes,
  withPostFailure,
  withPostSuccess
} from 'redux-recompose';

import {
  deleteCustomer,
  getCustomers,
  saveCustomer,
  updateCustomer
} from '../../services/customers';

export const actions = createTypes(
  completeTypes({
    primaryActions: ['GET_CUSTOMERS'],
    ignoredActions: ['ADD_CUSTOMER', 'DELETE_CUSTOMER', 'UPDATE_CUSTOMER']
  }),
  '@@CUSTOMERS'
);

const TARGETS = {
  CUSTOMERS: 'customers'
};

export const actionCreators = {
  getCustomers: payload => ({
    type: actions.GET_CUSTOMERS,
    target: TARGETS.CUSTOMERS,
    payload,
    service: getCustomers
  }),
  addCustomer: data => ({
    type: actions.ADD_CUSTOMER,
    payload: data,
    service: saveCustomer,
    injections: [
      withPostSuccess((dispatch, _response) => {
        dispatch(actionCreators.getCustomers({ page: 1 }));
      }),
      withPostFailure((_dispatch, _response) => {
        alert('No se pudo agregar el cliente');
      })
    ]
  }),
  deleteCustomer: id => ({
    type: actions.DELETE_CUSTOMER,
    payload: id,
    service: deleteCustomer,
    injections: [
      withPostSuccess((dispatch, _response) => {
        dispatch(actionCreators.getCustomers({ page: 1 }));
      }),
      withPostFailure((_dispatch, _response) => {
        alert('No se pudo eliminar el cliente');
      })
    ]
  }),
  updateCustomer: data => ({
    type: actions.UPDATE_CUSTOMER,
    payload: data,
    service: updateCustomer,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionCreators.getCustomers({ page: 1 }));
      }),
      withPostFailure((_dispatch, _response) => {
        alert('No se pudo actualizar el cliente');
      })
    ]
  })
};
