import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PrintTicket } from '../../components/PrintTicket';
import CustomButton from '../../components/CustomButton';

function PrintTicketScreen() {
  const componentRef = useRef();
  const navigate = useNavigate();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });
  const handleNewTicket = () => navigate('/ventaTicket');
  const { lastTicket } = useSelector(state => state.tickets);
  return (
    <React.Fragment>
      <PrintTicket ref={componentRef} {...lastTicket} />
      <CustomButton
        type={'button'}
        buttonStyle="btn--primary"
        onClick={handlePrint}>
        Imprimir
      </CustomButton>
      <CustomButton
        type={'button'}
        buttonStyle="btn--primary"
        onClick={handleNewTicket}>
        Nuevo
      </CustomButton>
    </React.Fragment>
  );
}

export default PrintTicketScreen;
