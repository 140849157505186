import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getMenusByUser } from '../../../config/services/menus';
import {
  addPermission,
  deletePermissionsByUser
} from '../../../config/services/permissions';
import CustomButton from '../../components/CustomButton';
import useLoading from '../../../hooks/useLoading';

import ItemMenu from './components/ItemMenu';

function UserPermissions() {
  const {
    state: { userId, userName }
  } = useLocation();
  const [menus, setMenus] = useState();
  const [loading, setLoading] = useState(false);
  const [updatingPermissions, setUpdatingPermissions] = useState(false);

  useLoading(loading || updatingPermissions);

  const getUserMenus = useCallback(async () => {
    setLoading(true);
    console.log(userId);
    const response = await getMenusByUser(userId);
    console.log(response);
    if (response.ok) {
      const [allMenus, permissions] = response.data;
      const mappedMenus = allMenus.map(item => {
        console.log(item);
        const permission = permissions.find(
          permission => permission.menu === item.id
        );
        console.log(permissions);
        return {
          ...item,
          checked: permissions.some(permission => permission.menu === item.id),
          permission: permission?.['permission'],
          permissionId: permission?.['id']
        };
      });
      setMenus(mappedMenus);
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    getUserMenus();
  }, [getUserMenus]);

  const handlePressCheck = menuId => {
    const newMenus = menus.map(menu => {
      if (menu.id === menuId) {
        return { ...menu, checked: !menu.checked };
      }
      return menu;
    });
    setMenus(newMenus);
  };

  const handleChangePermission = (menuId, permissionValue) => {
    const newMenus = menus.map(menu => {
      if (menu.id === menuId) {
        return { ...menu, permission: permissionValue };
      }
      return menu;
    });
    setMenus([...newMenus]);
  };

  const handlePressSave = async () => {
    setUpdatingPermissions(true);
    await deletePermissionsByUser(userId);

    await Promise.all(
      menus.map(menu => {
        if (menu.checked) {
          const permissionData = {
            user: userId,
            menu: menu.id,
            permission: menu.permission || 'VIEW'
          };
          return addPermission(permissionData);
        }
        return Promise.resolve();
      })
    );
    setUpdatingPermissions(false);
  };

  return (
    <div>
      <h2>Permisos de Usuario</h2>
      <h3>{userName}</h3>
      <div>
        {menus?.map(menu => (
          <ItemMenu
            key={menu.title}
            menu={menu}
            handlePressCheck={handlePressCheck}
            handleChangePermission={handleChangePermission}
          />
        ))}
      </div>
      <CustomButton type={'button'} onClick={handlePressSave}>
        Guardar
      </CustomButton>
    </div>
  );
}

export default UserPermissions;
