import React from 'react';
import { useSelector } from 'react-redux';

import './PrintTicket.css';

import PrintHeader from '../PrintHeader';
import { formattedDate } from '../../../utils/dateUtils';
import { travelRoutes } from '../../../config/constants/rutas';

export const PrintTicket = React.forwardRef(
  (
    {
      id,
      fecha,
      puestos,
      origen,
      destino,
      customer,
      precio,
      observaciones,
      usuario,
      estado,
      created_at
    },
    ref
  ) => {
    const travelRoute = travelRoutes.find(
      item =>
        item.origen.toLowerCase() === origen.toLowerCase() &&
        item.destino.toLowerCase() === destino.toLowerCase()
    );
    const { companies } = useSelector(state => state.companies);
    const currentCompany = companies.length > 0 ? companies[0] : null;
    return (
      <div ref={ref} className="ticket-container">
        <PrintHeader />
        <span className="ticket-anulado">{estado === 'ANULADO' && estado}</span>
        <hr />
        <table>
          <tr className="tr-border">
            <td>Tiquete No:</td>
            <td align="right">
              <strong>{id}</strong>
            </td>
          </tr>
        </table>
        Fecha de expedición:
        <p className="clear-space">
          <strong>{formattedDate(created_at)}</strong>
        </p>
        Fecha y hora de salida:
        <p className="clear-space">
          <strong>{fecha}</strong>
        </p>
        Cédula cliente:
        <p className="clear-space">
          <strong>{customer.id}</strong>
        </p>
        Nombre cliente:
        <p className="clear-space">
          <strong>{customer.nombre}</strong>
        </p>
        <table>
          <tr className="tr-border">
            <td>Puestos:</td>
            <td align="right">
              <strong>{puestos}</strong>
            </td>
          </tr>
          <tr className="tr-border">
            <td>Origen del pasajero:</td>
            <td align="right">
              <strong>{origen}</strong>
            </td>
          </tr>
          <tr className="tr-border">
            <td>Destino del pasajero:</td>
            <td align="right">
              <strong>{destino}</strong>
            </td>
          </tr>
          <tr className="tr-border">
            <td>Ruta:</td>
            <td align="right">
              <strong>{travelRoute.ruta || ''}</strong>
            </td>
          </tr>
          <tr className="tr-border">
            <td>Valor:</td>
            <td align="right">
              <strong>{`$ ${Number(precio).toLocaleString('es-ES')}`}</strong>
            </td>
          </tr>
        </table>
        <div className="observaciones">
          <strong>Observaciones:</strong>
          <p>{observaciones}</p>
        </div>
        <div className="observaciones text-footer">
          <p className="clear-space">{`Nombre empresa aseguradora:`}</p>
          <p>{currentCompany.insurance}</p>
          <p className="clear-space">{`Vendedor: ${usuario}`}</p>
          <p className="clear-space">{`Impreso por software transporte`}</p>
          <p className="clear-space">{`Servicio básico de pasajeros`}</p>
        </div>
      </div>
    );
  }
);
