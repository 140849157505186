import React, { memo } from 'react';

import './CustomTextInput.css';

function CustomTextInput({
  name,
  errors,
  required = false,
  register,
  title,
  column = '',
  customInputClass = '',
  customLabelClass = '',
  minLength,
  ...props
}) {
  return (
    <div
      className={`text-input-container ${column === '' ? 'col-auto' : column}`}>
      {title && (
        <label className={`label ${customLabelClass}`}>
          {' '}
          {title}
          <span className="required">{required && ' *'}</span>
        </label>
      )}
      <input
        {...register(name, { required, minLength })}
        className={`form-control ${customLabelClass}`}
        {...props}
      />
      {errors[name] && (
        <span className="error">
          {errors[name].type === 'minLength'
            ? `Este campo debe contener minimo ${minLength} caracteres`
            : 'Este campo es obligatorio'}
        </span>
      )}
    </div>
  );
}

export default memo(CustomTextInput);
