import {
  faTicket,
  faTicketAlt,
  faFile,
  faBoxOpen,
  faFileContract,
  faMoneyBill,
  faCheckSquare,
  faBoxes
} from '@fortawesome/free-solid-svg-icons';

export const ITEMS_MENU = [
  {
    icon: faTicket,
    text: 'Nuevo Tiquete',
    path: '/ventaTicket'
  },
  {
    icon: faFileContract,
    text: 'Generar Planilla',
    path: '/crear-planilla'
  },
  {
    icon: faBoxOpen,
    text: 'Nueva Remesa',
    path: '/nuevaRemesa'
  },
  {
    icon: faCheckSquare,
    text: 'Revisión Vehiculo',
    path: '/revision-vehiculo'
  },
  {
    icon: faTicketAlt,
    text: 'Tiquetes Vendidos',
    path: '/tiquetes'
  },
  {
    icon: faFile,
    text: 'Planillas Generadas',
    path: '/planillas'
  },
  {
    icon: faBoxes,
    text: 'Lista de Remesas',
    path: '/listado-remesas'
  },
  {
    icon: faMoneyBill,
    text: 'Pago Vehículo',
    path: '/pago-vehiculo'
  }
];
