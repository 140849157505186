import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import useLoading from '../../../../../hooks/useLoading';
import { uploadLogo } from '../../../../../config/services/companies';
import CustomButton from '../../../../components/CustomButton';

import './ModalForm.css';

function SelectFile({ show, handleClose, setUrlLogo }) {
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState();

  useLoading(uploading);

  const uploadFile = async () => {
    setUploading(true);
    const formData = new FormData();
    for (let index = 0; index < files.length; index++) {
      formData.append('file', files[index]);
    }
    const response = await uploadLogo(formData);
    console.log(response);
    response?.data?.message && alert(response.data.message);
    if (response.ok) {
      setUrlLogo(process.env.REACT_APP_URL_BASE + response.data.file);
    }
    setUploading(false);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <form id="form-upload-file">
          <label className="label">Logo</label>
          <input
            className="form-control"
            type="file"
            name="file"
            onChange={e => setFiles(e.target.files)}
          />
          <div className="btn-group">
            <CustomButton
              type={'button'}
              onClick={() => uploadFile()}
              value="upload">
              Subir
            </CustomButton>
            <CustomButton
              type={'button'}
              onClick={handleClose}
              buttonStyle={'btn--outline'}>
              Cancelar
            </CustomButton>
          </div>
        </form>
        <output id="output"></output>
      </Modal.Body>
    </Modal>
  );
}

export default SelectFile;
