import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CustomButton from '../../components/CustomButton';
import CustomTextInput from '../../components/CustomTextInput';
import SelectCustomerForm from '../../components/SelectCustomerForm';
import { actionCreators as remittanceActions } from '../../../config/redux/remittances/actions';
import { actionCreators as appActions } from '../../../config/redux/appReducer/actions';
import SelectVehicle from '../../components/SelectVehicle';

import './Remittance.css';

function Remittance() {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm();
  const dispatch = useDispatch();
  const { lastRemittanceLoading } = useSelector(state => state.remittances);
  const navigate = useNavigate();
  const onSubmit = data => {
    dispatch(remittanceActions.addRemittance(data, navigate));
  };
  useEffect(() => {
    dispatch(appActions.setAppLoading(lastRemittanceLoading));
  }, [lastRemittanceLoading, dispatch]);
  const commonProps = { errors, register };
  const commonCustomerProps = { getValues, setValue, ...commonProps };
  const handleCancel = () => document.getElementById('remittanceForm').reset();
  const currentUser = useSelector(state => state.user.currentUser);
  const onSuccess = () => {
    if (getValues().estado === 'INACTIVO') {
      alert('Vehículo inactivo');
      setValue('vehiculo', '');
      setValue('placa', '');
      setValue('marca', '');
      setValue('estado', '');
    }
  };
  return (
    <div>
      <h2 className="title-style">Nueva Remesa</h2>
      <form onSubmit={handleSubmit(onSubmit)} id="remittanceForm">
        <div>
          <hr />
          <CustomTextInput
            name={'usuario'}
            type={'hidden'}
            value={currentUser.name}
            {...commonProps}
          />
          <div className="row g-3">
            <CustomTextInput
              name={'origen'}
              required
              title={'ORIGEN'}
              {...commonProps}
              column="col-md-4"
            />
            <CustomTextInput
              name={'destino'}
              required
              title={'DESTINO'}
              {...commonProps}
              column="col-md-4"
            />
            <CustomTextInput
              name={'valor'}
              required
              title={'VALOR'}
              {...commonProps}
              column="col-md-3"
            />
            {/* <div className="col-md-1"> */}
            {/* <label className="label">PUERTA</label> */}
            <br />
            <input type="hidden" {...register('puerta')} />
            {/* </div> */}
          </div>
          <hr />
          <SelectCustomerForm
            title={'Distinatario'}
            {...commonCustomerProps}
            name={'nombre_destinatario'}
            phone={'telefono_destinatario'}
            idCliente="cedula_destinatario"
            customIdLabel="NIT O CÉDULA"
          />
          <div>
            <div className="row g-2">
              <CustomTextInput
                name={'direccion_destinatario'}
                required
                title={'DIRECCIÓN'}
                {...commonProps}
                column="col-md-8"
                customInputClass="input-large"
              />
            </div>
          </div>
          <hr />
          <div>
            <SelectCustomerForm
              title={'Remitente'}
              {...commonCustomerProps}
              name={'nombre_remitente'}
              phone={'telefono_remitente'}
              idCliente="cedula_remitente"
              customIdLabel="NIT O CÉDULA"
            />

            <div className="row g-2">
              <CustomTextInput
                name={'direccion_remitente'}
                required
                title={'DIRECCIÓN'}
                {...commonProps}
                column="col-md-8"
              />
            </div>
          </div>
          <hr />
          <SelectVehicle
            commonProps={commonProps}
            setValue={setValue}
            getValues={getValues}
            onSuccess={onSuccess}
            required
          />
          <hr />
          <CustomTextInput
            name={'contenido'}
            required
            title={'CONTENIDO'}
            {...commonProps}
          />
        </div>
        <CustomButton buttonStyle={'btn--primary'} type="submit">
          Guardar
        </CustomButton>
        <CustomButton
          buttonStyle={'btn--outline'}
          type="button"
          onClick={handleCancel}>
          Cancelar
        </CustomButton>
      </form>
    </div>
  );
}

export default Remittance;
