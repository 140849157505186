import React, { useState } from 'react';

import CustomTextInput from '../CustomTextInput';
import useLoading from '../../../hooks/useLoading';
import { getVehicleData } from '../../../config/services/vehicles';

function SelectVehicle({
  commonProps,
  getValues,
  setValue,
  required = false,
  onSuccess = () => null,
  onFailure = () => null
}) {
  const [loadingScreen, setLoadingScreen] = useState(false);
  useLoading(loadingScreen);
  const searchVehicle = async () => {
    const idVehicle = getValues('vehiculo');
    if (idVehicle !== '') {
      setLoadingScreen(true);
      const vehicleData = await getVehicleData(idVehicle);
      if (vehicleData.data) {
        setValue('placa', vehicleData.data.placa ?? '');
        setValue('marca', vehicleData.data.marca ?? '');
        setValue('vencimiento_soat', vehicleData.data.vencimiento_soat ?? '');
        setValue(
          'vencimiento_tec_mec',
          vehicleData.data.vencimiento_tec_mec ?? ''
        );
        setValue(
          'vencimiento_todo_riesgo',
          vehicleData.data.vencimiento_todo_riesgo ?? ''
        );
        setValue('estado', vehicleData.data.estado ?? '');
        setValue('razon_estado', vehicleData.data.razon_estado ?? '');
        onSuccess();
      }
      setLoadingScreen(false);
    } else {
      setValue('placa', '');
      setValue('marca', '');
      setValue('vencimiento_soat', '');
      setValue('vencimiento_tec_mec', '');
      setValue('vencimiento_todo_riesgo', '');
      setValue('estado', '');
      setValue('razon_estado', '');
      onFailure();
    }
  };
  return (
    <div className="row">
      <hr className="separator" />
      <h3>Vehículo</h3>
      <CustomTextInput
        name={'vehiculo'}
        {...commonProps}
        title={'NÚMERO'}
        column="col-md-3"
        required={required}
        onBlur={searchVehicle}
      />
      <CustomTextInput
        name={'placa'}
        {...commonProps}
        title={'PLACA'}
        column="col-md-3"
        readOnly
      />
      <CustomTextInput
        name={'marca'}
        {...commonProps}
        title={'MARCA'}
        column="col-md-3"
        readOnly
      />
    </div>
  );
}

export default SelectVehicle;
