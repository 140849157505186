import React, { memo } from 'react';
import { Form } from 'react-bootstrap';

function ItemMenu({ menu, handlePressCheck, handleChangePermission }) {
  const handleOnPressCheck = () => {
    handlePressCheck(menu.id);
  };

  const handleOnChangePermission = ({ target }) => {
    handleChangePermission(menu.id, target.value);
  };

  return (
    <div>
      <Form.Check
        type="checkbox"
        value={menu.id}
        checked={menu.checked}
        onChange={handleOnPressCheck}
        label={menu.title}
      />
      <Form.Select
        style={{ marginBottom: 20 }}
        disabled={!menu.checked}
        onChange={handleOnChangePermission}
        value={menu.permission}>
        <option value={'VIEW'}>{'VER'}</option>
        <option value={'EDIT'}>{'EDITAR'}</option>
      </Form.Select>
    </div>
  );
}

export default memo(ItemMenu);
