import {
  completeTypes,
  createTypes,
  withPostFailure,
  withPostSuccess
} from 'redux-recompose';

import {
  deleteDriver,
  getDrivers,
  saveDriver,
  updateConductor
} from '../../services/drivers';

export const actions = createTypes(
  completeTypes({
    primaryActions: ['GET_DRIVERS'],
    ignoredActions: ['ADD_DRIVER', 'DELETE_DRIVER', 'UPDATE_DRIVER']
  }),
  '@@DRIVERS'
);

const TARGETS = {
  DRIVERS: 'drivers'
};

export const actionCreators = {
  getDrivers: payload => ({
    type: actions.GET_DRIVERS,
    target: TARGETS.DRIVERS,
    payload,
    service: getDrivers
  }),
  addDriver: data => ({
    type: actions.ADD_DRIVER,
    payload: data,
    service: saveDriver,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionCreators.getDrivers({ page: 1 }));
      }),
      withPostFailure((_dispatch, _response) => {
        alert('No se pudo agregar el conductor');
      })
    ]
  }),
  deleteDriver: id => ({
    type: actions.DELETE_DRIVER,
    payload: id,
    service: deleteDriver,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionCreators.getDrivers({ page: 1 }));
      }),
      withPostFailure((_dispatch, _response) => {
        alert('No se pudo eliminar el conductor');
      })
    ]
  }),
  updateDriver: data => ({
    type: actions.UPDATE_DRIVER,
    payload: data,
    service: updateConductor,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(actionCreators.getDrivers({ page: 1 }));
      }),
      withPostFailure((_dispatch, _response) => {
        alert('No se pudo actualizar el conductor');
      })
    ]
  })
};
