import React from 'react';
import Table from 'react-bootstrap/Table';

import TablePagination from '../../../../components/TablePagination';
import PaymentRow from '../PaymentRow';

function PaymentsTable({
  payments: { data, current_page, last_page },
  tableId,
  page,
  setPage
}) {
  const headers = [
    { title: 'NÚMERO' },
    { title: 'VEHICULO' },
    { title: 'PLACA' },
    { title: 'MES' },
    { title: 'AÑO' },
    { title: 'FECHA' },
    { title: 'ESTADO' }
  ];
  const columnsHeader = headers.map((item, index) => (
    <th align="center" key={index}>
      {item.title}
    </th>
  ));
  const rowsBody = data.map(item => <PaymentRow {...item} />);
  return (
    <div>
      <Table className="custom-table" id={tableId}>
        <thead>
          <tr>{columnsHeader}</tr>
        </thead>
        <tbody>{rowsBody}</tbody>
      </Table>
      {current_page && (
        <TablePagination
          currentPage={current_page}
          lastPage={last_page}
          setPage={setPage}
          page={page}
        />
      )}
    </div>
  );
}

export default PaymentsTable;
