import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { utils, writeFile } from 'xlsx';
import { useNavigate } from 'react-router-dom';

import SpreadsheetsTable from '../../components/SpreadsheetsTable';
import { actionCreators as spreadsheetsActions } from '../../../config/redux/spreadsheets/actions';
import CustomTextInput from '../../components/CustomTextInput';
import SelectCitiesPrice from '../../components/SelectCitiesPrice';
import CustomButton from '../../components/CustomButton';
import useLoading from '../../../hooks/useLoading';
import EmptyTable from '../../components/EmptyTable';
import { currencyFormatter } from '../../../utils/currencyUtils';
import { usePermissions } from '../../../hooks/usePermissions';

function Spreadsheets() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const {
    companies: { companies, companiesLoading },
    spreadsheets: { spreadsheets, spreadsheetsLoading }
  } = useSelector(state => state);
  const currentCompany = companies[0];
  const hasPermissionEdit = usePermissions();

  useLoading(spreadsheetsLoading || companiesLoading);

  const activeSpreadsheets = spreadsheets?.data?.filter(
    spreadsheet => spreadsheet.estado === 'ACTIVA'
  );
  const totalSpreadsheets = useMemo(
    () =>
      activeSpreadsheets
        ? activeSpreadsheets?.length * (currentCompany?.price || 0)
        : 0,
    [activeSpreadsheets, currentCompany?.price]
  );

  const totalAdditional = useMemo(
    () =>
      activeSpreadsheets
        ? activeSpreadsheets.reduce(
            (total, item) => total + (Number(item.additional) || 0),
            0
          )
        : 0,
    [activeSpreadsheets]
  );

  useEffect(() => {
    dispatch(spreadsheetsActions.getSpreadsheets({ page }));
  }, [dispatch, page]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  const commonProps = { errors, register };
  const { prices } = useSelector(state => state.prices);
  const onSubmit = data =>
    dispatch(spreadsheetsActions.getSpreadsheets({ page: 1, ...data }));
  const handleSeeAll = () => {
    dispatch(spreadsheetsActions.getSpreadsheets({ page: 1 }));
    reset();
  };
  const handleExportToXlsx = () => {
    const table_elt = document.getElementById('spreadsheetTable');
    const workbook = utils.table_to_book(table_elt);
    const ws = workbook.Sheets['Sheet1'];
    utils.sheet_add_aoa(ws, [['Created ' + new Date().toISOString()]], {
      origin: -1
    });
    writeFile(workbook, 'Planillas.xlsx');
  };
  const handleNewSpreadsheet = () => navigate('/crear-planilla');

  return (
    <>
      <h2>Planillas</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <CustomTextInput
            name={'id'}
            title={'PLANILLA'}
            {...commonProps}
            column="col-md-2"
          />
          <CustomTextInput
            name={'vehiculo'}
            title={'VEHÍCULO'}
            {...commonProps}
            column="col-md-2"
          />
          <SelectCitiesPrice
            name={'origen'}
            {...commonProps}
            prices={prices}
            column="col-md-3"
          />
          <SelectCitiesPrice
            name={'destino'}
            {...commonProps}
            prices={prices}
            column="col-md-3"
          />
          <CustomTextInput
            name={'conductor'}
            title={'CONDUCTOR'}
            {...commonProps}
            column="col-md-2"
          />
        </div>
        <div className="row">
          <CustomTextInput
            name={'created_ini'}
            title={'F. creación ini.'}
            {...commonProps}
            column="col-md-2"
            type={'date'}
          />
          <CustomTextInput
            name={'created_fin'}
            title={'F. creación fin.'}
            {...commonProps}
            column="col-md-2"
            type={'date'}
          />
          <CustomTextInput
            name={'ticket'}
            title={'TIQUETE'}
            {...commonProps}
            column="col-md-2"
          />
          <CustomTextInput
            name={'usuario'}
            title={'USUARIO'}
            {...commonProps}
            column="col-md-2"
          />
        </div>
        <div>
          <CustomButton type={'submit'}>Filtrar</CustomButton>
          <CustomButton
            type={'button'}
            buttonStyle={'btn--outline'}
            onClick={handleSeeAll}>
            Ver todos
          </CustomButton>
          <CustomButton
            type={'button'}
            buttonStyle={'btn--outline'}
            onClick={handleExportToXlsx}>
            Exportar
          </CustomButton>
          <CustomButton
            type={'button'}
            buttonStyle="btn--primary"
            onClick={handleNewSpreadsheet}>
            Nueva
          </CustomButton>
        </div>
      </form>
      <h3>{`Total Planillas: ${currencyFormatter(totalSpreadsheets || 0)}`}</h3>
      <h3>{`Total Adicional Planilla: $ ${totalAdditional.toLocaleString(
        'es-ES'
      )}`}</h3>
      {spreadsheets?.data?.length ? (
        <SpreadsheetsTable
          rows={spreadsheets}
          tableId="spreadsheetTable"
          hasPermissionEdit={hasPermissionEdit}
          page={page}
          setPage={setPage}
        />
      ) : (
        <EmptyTable />
      )}
    </>
  );
}

export default Spreadsheets;
