import React from 'react';
import Table from 'react-bootstrap/Table';

import TablePagination from '../../../../components/TablePagination';
import RemittanceRow from '../RemittanceRow';

function RemittancesTable({
  remittances: { data, current_page, last_page },
  tableId,
  hasPermissionEdit,
  page,
  setPage
}) {
  const headers = [
    { title: 'NÚMERO' },
    { title: 'ORIGEN' },
    { title: 'DESTINO' },
    { title: 'PRECIO' },
    { title: 'C. DESTINATARIO' },
    { title: 'NOMBRE DESTINATARIO' },
    { title: 'C. REMITENTE' },
    { title: 'NOMBRE REMITENTE' },
    { title: 'USUARIO' },
    { title: 'ESTADO' },
    { title: 'FECHA' }
  ];
  const columnsHeader = headers.map((item, index) => (
    <th align="center" key={index}>
      {item.title}
    </th>
  ));
  const rowsBody = data.map(item => (
    <RemittanceRow {...item} hasPermissionEdit={hasPermissionEdit} />
  ));
  return (
    <>
      <Table className="custom-table" id={tableId}>
        <thead>
          <tr>{columnsHeader}</tr>
        </thead>
        <tbody>{rowsBody}</tbody>
      </Table>
      {current_page && (
        <TablePagination
          currentPage={current_page}
          lastPage={last_page}
          setPage={setPage}
          page={page}
        />
      )}
    </>
  );
}

export default RemittancesTable;
